import { useEffect } from 'react';

export default function useFunctionsReturn (func, setItem, dependencies = [], checkNull = true) {
  useEffect(() => {
    // 依存するパラメータのいずれかにnull / undefinedが含まれる、かつ空チェックする設定の場合は処理をスキップ
    // （パラメータにnull等を渡すことを想定している場合は「checkNull = false」を指定すること）
    if(checkNull && dependencies.some((d) => d == null)) {
      return;
    }
    func().then((response) => {
      setItem(response.data);
      if (response.data.error) {
        console.error(response.data.error);
      }
    });
  }, dependencies);
};

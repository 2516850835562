import { LiffMockPlugin } from '@line/liff-mock';
import env from './env';

const { liff } = window;
let mock = false;

if(env('CLOUD_FUNCTIONS_EMULATOR') === 'true') {
  liff.use(new LiffMockPlugin());
  mock = true;
  liff.$mock.set(
    (p) => ({
      ...p,
      // ローカル環境では下記のLineユーザに向けてボットがメッセージを送ります。
      // ※1 実在しないLineプロフィールを指定するとLineボット処理が途中でコケます
      // ※2 UserIdはLineのプロフィールに記載のIDではなく内部的なものです。
      //    STG環境などでpeopleテーブルなどの値を見ないとまず分からないでしょう。
      getProfile: {
        displayName: env('LINE_DISPLAY_NAME'),
        userId: env('LINE_USER_ID')
      },
      // ローカル環境では下記のアクセストークンに紐づくLineユーザを送信元としてボットがメッセージを送ります。
      // （各企業の設定の「チャネルアクセストークン」と一致します）
      getAccessToken: env('LINE_ACCESS_TOKEN')
    })
  );
}

export { liff, mock };

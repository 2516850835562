import React from 'react';
import { Button, Modal, Form, } from 'reactstrap';

import OverlayLoading from '../OverlayLoading';
import { mapValues } from 'lodash';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import classnames from 'classnames';

const { entries } = Object;

export default function CompanySettingsFormModal(props) {
  const { isOpen, onClickClose, values, title = '', isLoading, fields } = props;
  const isNew = !values;
  const statedFields = useFormState(values, fields, isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({ ...mapValues(statedFields, 'value') });
  };
  return (
    <Modal isOpen={isOpen}>
      <div class="modal__inner">
        <div class="modal__header">
          <h3 class="heading2">
            {title}{isNew ? '追加' : '編集'}
          </h3>
          <div class="modal__close js-close-modal">
            <span class="material-icons icn" onClick={onClickClose}>close</span>
          </div>
        </div>
        <div class="modal__body">
          <Form onSubmit={onSubmit} className="form">
            <table class="form__table">
              <tbody>
                {
                  entries(statedFields).map(([fieldName, fieldSetting]) => (
                    <Field
                      key={fieldName}
                      name={fieldName}
                      { ...{ ...fieldSetting, ...{ isNew } } }
                    />
                  ))
                }
              </tbody>
            </table>
            <div class="form__buttons">
              <Button className="button -secondary" onClick={onClickClose}>
                <span class="txt">キャンセル</span>
              </Button>
              <Button className={classnames('button -primary', {'-inactive': isUnsubmittable})} onClick={onSubmit} disabled={isUnsubmittable}>
                <span class="txt">保存</span>
              </Button>
            </div>
          </Form>
          { isLoading && (<OverlayLoading isOpen={isLoading} text={'インポート中'} />) }
        </div>
      </div>
    </Modal>
  );
};

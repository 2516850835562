import React from 'react';
import { pick, keyBy, } from 'lodash';
import { format as formatDate } from 'date-fns';

import firebase from '../../firebase';
import { benefitTargetTypes } from '../../shared/config';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import CompanyPage from '../hocs/CompanyPage';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import DeleteButton from '../DeleteButton';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(function CompanyBenefits(props) {
  const { match: { params: { companyId } } } = props;
  const benefits = useCollectionSubscription(companiesRef.doc(companyId).collection('benefits').orderBy('createdAt', 'desc'), [companyId]);
  const people = useCollectionSubscription(companiesRef.doc(companyId).collection('people'), [companyId]);
  const entries = useCollectionSubscription(companiesRef.doc(companyId).collection('entries'), [companyId]);

  const peopleByLineUserId = keyBy(people, 'lineProfile.userId');
  const entryByLineUserId = keyBy(entries,'lineProfile.userId');
  const benefitsById = keyBy(benefits, 'id');
  const processRow = (batch, row) => {
    const { title, body,type } = row;
    const id = body.replace(/\//g, '%2F');
    const exists = benefitsById[id] != null;
    const ref = companiesRef.doc(companyId).collection('benefits').doc(id);
    batch[exists ? 'update' : 'set'](ref, { title, body, type, ...(!exists && { createdAt: new Date(), sentAt: null, sentTo: null, sentToType: null }) });
  };

  return (
    <div className="contents__main">
      <main className="main">
        <div className="pageTitle">
          <h1 className="heading1">特典一覧</h1>
        </div>
        <section className="main__section">
          
          <div className="buttons -line">
            <ImportButton processRow={processRow} acceptedFields={['title', 'body', 'type']} />
            <ExportButton fileName="特典.csv" rows={benefits.map(_ => pick(_, ['title', 'body', 'type']))} />
          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>タイトル</th>
                  <th>種類</th>
                  <th>内容</th>
                  <th>登録日時</th>
                  <th>送信日時</th>
                  <th>送信先</th>
                  <th>送信先タイプ</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  benefits.map((benefit) => {
                    const { id, title, type, body, sentAt, sentTo, sentToType, createdAt } = benefit;
                    return (
                      <tr key={id}>
                        <td>
                          {title}
                        </td>
                        <td>
                          {type}
                        </td>
                        <td className="text-truncate" style={{ maxWidth: '15vw' }}>
                          {body}
                        </td>
                        <td>
                          {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                        </td>
                        <td>
                          {sentAt && formatDate(sentAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                        </td>
                        <td>
                          {
                          sentTo && 
                          (
                            (peopleByLineUserId[sentTo] && peopleByLineUserId[sentTo].name) ||
                            (entryByLineUserId[sentTo] && entryByLineUserId[sentTo].lastName) 
                          )
                          }
                        </td>
                        <td>
                          {benefitTargetTypes[sentToType]}
                        </td>
                        <td>
                          <div class="edit">
                            <DeleteButton itemRef={companiesRef.doc(companyId).collection('benefits').doc(id)} />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </div>
  );
});

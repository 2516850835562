import React, { Component } from 'react';
import { Button, Modal, Form, } from 'reactstrap';
import { isEmpty } from 'lodash';

import FormMixin from '../hocs/FormMixin';

const { entries } = Object;

export default FormMixin(class EventFormModal extends Component {
  static fields = {
    name: {
      ja: '会社名',
      type: 'string',
      validations: { required:  v => !isEmpty(v), }
    },
  }
  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.isOpen && this.props.isOpen) {
      this.clear();
      this.setValues();
    }
  }
  render() {
    const { isOpen, onClickClose, values = {} } = this.props;
    const isNew = values.id == null;
    return (
      <Modal isOpen={isOpen}>
        <div class="modal__inner">
          <div class="modal__header">
            <h3 class="heading2">
            会社{isNew ? '追加' : '編集'}
            </h3>
            <div class="modal__close js-close-modal">
              <span class="material-icons icn" onClick={onClickClose}>close</span>
            </div>
          </div>
          <div class="modal__body">
            <Form onSubmit={this.onSubmit} className="form">
            <table class="form__table">
            <tbody>
              {
                entries(EventFormModal.fields).map(([fieldName, fieldSetting]) => {
                  return this.renderField(fieldName, fieldSetting);
                })
              }
              </tbody>
              </table>
              <div class="form__buttons">
                <Button className="button -secondary" onClick={onClickClose}>
                  <span class="txt">キャンセル</span>
                </Button>
                <Button className={
                  ['button','-primary', 
                  (this.isUnsubmittable()) ? '-inactive': ''].join(' ')
                } onClick={this.onSubmit} disabled={this.isUnsubmittable()}>
                  <span class="txt">保存</span>
                </Button>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    );
  }
});

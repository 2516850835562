import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class CompanySidebarNav extends Component {
  render() {
    const { user, adminUser, match: { params: { companyId } } } = this.props;
    const isAdmin = adminUser || (user.role === 'admin');
  
    const menus = [];

    if (isAdmin) {
        menus.push({title: '各種設定', icn: 'settings', to: `/companies/${companyId}/settings`});
        menus.push({title: 'ユーザー一覧',icn: 'account_circle',to: `/companies/${companyId}/users`});
        menus.push({title: '応募先一覧',icn: 'store',to: `/companies/${companyId}/shops`});
    }
    menus.push({title:'登録者一覧',icn:'supervisor_account',to: `/companies/${companyId}/peoples`});

    if (isAdmin) {
        menus.push({title:'特典一覧', icn:'redeem', to: `/companies/${companyId}/benefits`});
        menus.push({title:'メッセージテンプレート一覧', icn:'mail_outline', to: `/companies/${companyId}/messageTemplates`});
    }
    menus.push({title: '応募一覧',icn: 'description', to: `/companies/${companyId}/entries`});

    return (
        <div className="contents__nav">
        <nav className="nav">
          <ul className="nav__list">
            { 

            menus.map((menu,i) => {
                const has_active = (menu.to === this.props.location.pathname);
                return (
                <li key={i} className="nav__list__item">
                    <Link to={menu.to} className={['nav-link','nav__list__link', (has_active) ? '-active': ''].join(' ')}>
                        <span className="material-icons icn">{menu.icn}</span>
                        <span className="txt">{menu.title}</span>
                    </Link>
                </li>
                );
            })

            }
          </ul>
        </nav>
      </div>

    );
  }
}

import React from 'react';
import { Button } from 'reactstrap';
import { chunk } from 'lodash';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { useToggle } from 'react-use';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import { adminSettingFields as companyAdminSettingsFields, } from '../../shared/models/company';
import EditButton from '../EditButton';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import CompanySettingsFormModal from '../modals/CompanySettingsFormModal';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default AdminPage(function AdminCompany(props) {
  const { match: { params: { companyId } } } = props;
  const company = useDocumentSubscription(companiesRef.doc(companyId), [companyId]);
  const [isResetting, toggleResetting] = useToggle();
  const resetData = async () => {
    if(!window.confirm('本当にリセットしますか？')) return;
    toggleResetting();
    try {
      await ['benefits', 'shops', 'people', 'entries'].reduce(async (x, collectionName) => {
        await x;
        const { docs } = await companiesRef.doc(companyId).collection(collectionName).get();
        await chunk(docs, 500).reduce(async (x, docs) => {
          await x;
          const batch = db.batch();
          docs.forEach(_ => batch.delete(_.ref));
          await batch.commit();
        }, Promise.resolve());
      }, Promise.resolve());
      toast.success('リセットしました');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleResetting();
  };

  if (!company) {return <div />} ;

  return (
    <div className="contents__main">
    <main className="main">
      <div className="pageTitle">
        <h1 className="heading1">
          {company.name}
        </h1>
      </div>

      <section className="main__section">
        <div className="buttons -line">
          <Link to={`/companies/${companyId}`} target="_blank">
            <span className="material-icons icn">business</span>
            会社向け管理画面へ
          </Link>
        </div>
      </section>

      <section className="main__section">
        <h2 class="heading2">各種設定</h2>
        <div className="buttons -line">
          <EditButton itemRef={company.ref} FormModal={CompanySettingsFormModal} formProps={{ admin: true }} />
        </div>
        <div className="table">
          <table>
            <tbody>
            <tr>
              <th>{companyAdminSettingsFields.entryActionWaitingDays.label}</th>
              <td>
                {company.entryActionWaitingDays || companyAdminSettingsFields.entryActionWaitingDays.initialValue}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </section>

      <section className="main__section">
      <h2 class="heading2">アクション</h2>
        <div className="buttons -line">
          <Button className="button -primary" onClick={resetData} disabled={isResetting} >
            <span className="material-icons icn">warning_amber</span>
            <span className="txt">応募先、社員、応募、特典等のリセット</span>
          </Button>
        </div>
      </section>
    </main>
    </div>
  );
});

import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import AdminInvitationModal from '../modals/AdminInvitationModal';

const db = firebase.firestore();
const usersRef = db.collection('users');

export default AdminPage(class AdminCompanies extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount(){
    this.listenCompanies();
  }
  listenCompanies() {
    usersRef
      .onSnapshot(({ docs }) => {
        this.setState({ users: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  onClickAdd = () => {
    this.setState({ targetValues: undefined });
    this.openFormModal();
  }
  delete = async (id) => {
    if (!window.confirm('本当に削除しますか？')) return;
    await usersRef.doc(id).delete();
    toast.success('削除しました');
  }
  onClickInvite = () => {
    this.openModal('InvitationModal');
  }
  openModal = (name) => this.setState({ [`shouldShow${name}`]: true })
  closeModal = (name) => this.setState({ [`shouldShow${name}`]: false })
  render () {
    const { users = [], shouldShowInvitationModal = false, } = this.state;
    return (
      <div className="contents__main">
      <main className="main">
        <div className="pageTitle">
          <h1 className="heading1">管理者一覧</h1>
        </div>
        <section className="main__section">
          
          <div className="buttons -line">
            <Button className="button -primary" onClick={this.onClickInvite}>
              <span className="material-icons icn">add</span>
              <span className="txt">管理者を招待</span>
            </Button>
          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>名前</th>
                  <th>メールアドレス</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  users.map((user) => {
                    const { id, displayName, email } = user;
                    return (
                      <tr key={id}>
                        <td>
                          {displayName}
                        </td>
                        <td>
                          {email}
                        </td>
                        <td>
                          <div className="edit">
                          <Button onClick={this.delete.bind(this, id)}>
                            <span class="material-icons icn">delete</span>
                          </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </section>
      </main>
      <AdminInvitationModal isOpen={shouldShowInvitationModal} onClickClose={this.closeModal.bind(this, 'InvitationModal')} />
      </div>
    );
  }
});

import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ConfirmModal from './ConfirmModal';

import firebase from '../firebase';

const auth = firebase.auth();

export default class Root extends Component {
  componentWillMount() {
    if(process.env.REACT_APP_ENV === 'test') {
      auth.onAuthStateChanged((firebaseUser) => {
        if(firebaseUser == null) {
          auth.signInAnonymously();
        }
        window.firebaseUser = firebaseUser;
      });
    }
  }
  render() {
    return (
      <div>
        <BrowserRouter>
          {this.props.routes()}
        </BrowserRouter>
        <ToastContainer />
        <ConfirmModal />
      </div>
    );
  }
};

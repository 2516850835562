import { useState, useEffect } from 'react';
import { liff, mock } from '../../liff';

export default function useLineProfile (liffId) {
  const [lineProfile, setLineProfile] = useState();
  const initLiff = async () => {
    await liff.init({ liffId, mock });
    if(!liff.isLoggedIn()) {
      await liff.login();
    }
  }
  const getLineProfile = async () => {
    const lineProfile = await liff.getProfile();
    setLineProfile(lineProfile);
  }
  useEffect(() => {
    if(!liffId) return;
    (async () => {
      await initLiff();
      await getLineProfile();
    })();
  }, [liffId]);
  return lineProfile;
};

import React from 'react';
import { omitBy, keyBy, } from 'lodash';
import { format as formatDate, } from 'date-fns';

import firebase from '../../firebase';
import { fields, settingFields } from '../../shared/models/entry';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import EntrySettingsFormModal from '../modals/EntrySettingsFormModal';
import CompanyEntryChats from '../modals/CompanyEntryChats';
import CompanyEntryBenefits from '../modals/CompanyEntryBenefits'
import CompanyPage from '../hocs/CompanyPage';
import EditButton from '../EditButton';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(function CompanyEntry(props) {
  const { match: { params: { companyId, entryId } } } = props;
  const shops = useCollectionSubscription(companiesRef.doc(companyId).collection('shops').orderBy('index', 'asc'), [companyId]);
  const company = useDocumentSubscription(companiesRef.doc(companyId), [companyId]);
  const entry = useDocumentSubscription(companiesRef.doc(companyId).collection('entries').doc(entryId), [companyId, entryId]);
  const [referrer] = useCollectionSubscription(entry && companiesRef.doc(companyId).collection('people').where('lineProfile.userId', '==', entry.referrerId), [companyId, entry]);
  const { entryFormSettings = {} } = company || {};
  const { customItems = [] } = entryFormSettings;
  const shopById = keyBy(shops, 'id');
  const baseFields = omitBy(
    fields(),
    (v, k) => (entryFormSettings[k] || {}).enabled === false,
  );
  const fieldsByCustomItems = customItems.reduce((x, { description, type, }) => {
    return {
      ...x,
      [description]: {
        label: description,
        type,
      },
    };
  }, {});
  const allFields = { ...baseFields, ...fieldsByCustomItems, ...settingFields };

  return (
    <div className="contents__main">
      <main className="main">
        <div className="pageTitle">
          <h1 className="heading1">応募詳細</h1>
        </div>
        <section className="main__section">
          
          <div className="buttons -line">
            <EditButton itemRef={companiesRef.doc(companyId).collection('entries').doc(entryId)} FormModal={EntrySettingsFormModal} />
          </div>
          <div className="table">
            {
              entry && (
                <table>
                  <tbody>
                    <tr>
                      <th>紹介者</th>
                      <td>{referrer && referrer.name}</td>
                    </tr>
                    {
                      Object.entries(allFields).map(([fieldName, { label, type }]) => {
                        const _value = entry[fieldName];
                        const value = (({
                          date: () => _value && formatDate(_value.toDate(), 'yyyy/MM/dd'),
                          datetime: () => _value && formatDate(_value.toDate(), 'yyyy/MM/dd HH:mm'),
                        })[type] || (_ => _value))();

                        return (
                          <tr key={fieldName}>
                            <th>{label}</th>
                            <td style={{ whiteSpace: 'pre-line' }}>{shopById[_value] ? `${(shopById[value] || {}).name}（${value}）` : value}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </table>
              )
            }
          </div>
        </section>


        <CompanyEntryBenefits companyId={companyId} entryId={entryId} />
        <CompanyEntryChats companyId={companyId} entryId={entryId} />
      </main>
    </div>
  );
});

import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { pick, keyBy, isUndefined } from 'lodash';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import CompanyPage from '../hocs/CompanyPage';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import DeleteButton from '../DeleteButton';
import CompanyImportFormModal from '../modals/CompanyImportFormModal';
import { fields } from '../../shared/models/shop';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(function CompanyShops(props) {
  const {
    match: {
      params: { companyId },
    },
  } = props;
  const shops = useCollectionSubscription(
    companiesRef
      .doc(companyId)
      .collection('shops')
      .orderBy('index', 'asc'),
    [companyId]
  );

  const shopsById = keyBy(shops, 'id');
  const [shouldShowFormModal, setshouldShowFormModal] = useState(false);
  const [targetValues, setTargetValues] = useState(undefined);
  const processRow = (batch, row, index) => {
    const { id, name, area_name, isEntry } = row;

    if (!id || !name) return;
    const exists = shopsById[id] != null;
    const ref = companiesRef.doc(companyId).collection('shops').doc(id);
    //CSVインポートでなく、入力から取り込むときに（新規のみ）同じidは弾く
    if (exists && isUndefined(index) && !targetValues) {
      throw new Error(`${id} はすでに使用されている応募先IDです`);
    }

    const upperCaseIsEntry = isEntry.toUpperCase();
    if (upperCaseIsEntry !== 'ON' && upperCaseIsEntry !== 'OFF') {
      throw new Error(`募集は ON か OFF を 入力してください`);
    }

    // 個別入力の場合は先頭へ配置する
    if (isUndefined(index)){
      if (shops.length === 0 ){
        index = 10000;
      }else{
        // 更新の場合はindexを更新しない
        if (! exists){
          index = shops[0].index - 1;
        }
      }
    }
    const values = { id, name, area_name, isEntry: upperCaseIsEntry, ...((index) && {index}), ...(!exists && { createdAt: new Date() }) };
    if (batch) {
      batch.set(ref, values, { merge: true });
    } else {
      ref.set(values, { merge: true });
    }
  };
  const openFormModal = () => setshouldShowFormModal(true);
  const closeFormModal = () => setshouldShowFormModal(false);
  const onSubmitForm = async (values) => {
    try {
      await processRow(null, values);
      const { id } = values;
      const exists = shopsById[id];
      toast.success(`${exists != null ? '編集' : '追加'}しました`);
    } catch (e) {
      console.error(e);
      toast.error(e.message);
    }
    closeFormModal();
  };
  const onClickAdd = (value) => {
    setTargetValues(undefined);
    openFormModal();
  };
  const onClickEdit = (value) => {
    setTargetValues(value);
    openFormModal();
  };

  return (
    <div className="contents__main">
      <main className="main">
        <div className="pageTitle">
          <h1 className="heading1">応募先一覧</h1>
        </div>
        <section className="main__section">
          
          <div className="buttons -line">
          <button className="button -primary" onClick={onClickAdd}>
              <span className="material-icons icn">add</span>
              <span className="txt">新規追加</span>
            </button>
            <ImportButton
              processRow={processRow}
              acceptedFields={['area_name', 'id', 'name', 'isEntry']}
            />
            <ExportButton
              fileName="応募先.csv"
              rows={shops.map((_) => pick(_, ['area_name','id', 'name', 'isEntry']))}
            />
          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>エリア名</th>
                  <th>応募先ID</th>
                  <th>応募先名</th>
                  <th>募集</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {shops.map((shop) => {
                  const { id, name, area_name, isEntry } = shop;
                  return (
                    <tr key={id}>
                      <td>
                        {
                         (area_name) ? 
                         <>{area_name}</> : <>-</>
                        }
                      </td>
                      <td>{id}</td>
                      <td>{name}</td>
                      <td>{isEntry}</td>
                      <td>
                        <div className="edit">
                          <Button
                            onClick={onClickEdit.bind(this, shop)}
                          >
                            <span className="material-icons icn">edit_note</span>
                          </Button>
                          <DeleteButton
                            itemRef={companiesRef
                              .doc(companyId)
                              .collection('shops')
                              .doc(id)}
                          />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </section>
      </main>
      <CompanyImportFormModal
        values={targetValues}
        isOpen={shouldShowFormModal}
        onClickClose={closeFormModal}
        onSubmit={onSubmitForm}
        fields={fields}
        title={'応募先'}
      />
    </div>
  );
});

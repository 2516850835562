import React from 'react';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import copy from 'copy-to-clipboard';

export default function CopyButton({ content, ...extraProps }) {
  const onClick = () => {
    copy(content);
    toast.success('コピーしました');
  };

  return (
    <Button className="button -ghost -inline" onClick={onClick} {...extraProps}>
      <span className="material-icons icn">content_copy</span>
    </Button>
  );
};

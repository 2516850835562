import { useState, useEffect } from 'react';

const { entries } = Object;

export default function useFormState (values, fields, shouldReset) {
  const initialState = entries(fields)
    .reduce((x, [fieldName, fieldSetting]) => {
      const { type, initialValue } = fieldSetting;
      const value = (values || {})[fieldName];
      const dateValue = () => (value && value.toDate) ? value.toDate() : (value || initialValue || null);
      return {
        ...x,
        [fieldName]: (
          ({
            datetime: dateValue,
            date: dateValue,
            time: dateValue,
          })[type] || (() =>
          value != null ? value : initialValue != null ? initialValue : null
        ))(),
      };
    }, {});
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if(shouldReset) setState(initialState);
  }, [shouldReset]);

  return entries(fields).reduce((x, [fieldName, fieldSetting]) => {
    const { validations = {}, hidden = _ => false } = fieldSetting;
    const shouldHide = hidden(state);
    const value = shouldHide ? null : state[fieldName];
    const validationErrors = shouldHide ? [] : entries(validations)
      .filter(([k, v]) => !v(value, state, fieldName))
      .map(([k]) => k);
    return {
      ...x,
      [fieldName]: {
        ...fieldSetting,
        value,
        setValue: v => setState({ ...state, [fieldName]: v }),
        setValues: (values) => setState(values),
        validationErrors,
        isValid: validationErrors.length === 0,
        shouldHide,
        values: state,
      },
    };
  }, {});
};

import React from 'react';
import { isUndefined, keyBy } from 'lodash';

import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { entryStatuses } from '../../shared/config';
import { Link } from 'react-router-dom';
import { format as formatDate} from 'date-fns';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default function CompanyPeopleShares(props){
    const { companyId, peopleId } = props;

    const people = useDocumentSubscription(
        companiesRef
        .doc(companyId)
        .collection('people')
        .doc(peopleId),
        [companyId, peopleId]
        );

    const allEntries = useCollectionSubscription(companiesRef.doc(companyId).collection('entries').orderBy('createdAt', 'desc'), [companyId]);

    if (isUndefined(people) || allEntries.length === 0) return <></>;

    const entryByLineUserId = keyBy(allEntries, 'lineProfile.userId');

    const entriesWithInfo = (people.entryLineUserIds || []).map((entryLineUserId) => {
        const entry = entryByLineUserId[entryLineUserId];
        return {
          entryLineUserId,
          entry
        };
      });

    return (
    <section className="main__section">
        <h2 className="heading2">シェア一覧</h2>

        <div className="table">
            <table>
            <thead>
            <tr>
                <th>状態</th>
                <th>応募者名</th>
                <th>応募日時</th>
            </tr>
            </thead>
            <tbody>
                {
                    entriesWithInfo.map((entryWithInfo) => {
                        const { entry = {}, entryLineUserId } = entryWithInfo;
                        const { id, name, firstName, lastName, createdAt, status } = entry;

                        return (
                            <tr key={entryLineUserId}>
                                <td className="text-nowrap">
                                    {entryStatuses[status] || '未応募'}
                                </td>
                                <td>
                                {id ? (
                                    <Link to={`/companies/${companyId}/entries/${id}`}>
                                        {name || `${lastName} ${firstName}`}
                                    </Link>
                                ) : entryLineUserId}
                                </td>
                                <td>
                                    {createdAt ? formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss') : ''}
                                </td>
                            </tr>
                        )
                    })
                }
            </tbody>
            </table>
        </div>
    </section>
  );
};

import React from 'react';
import { Button, Form, } from 'reactstrap';
import { pick, isEmpty, mapValues } from 'lodash';
import { useToggle } from 'react-use';
import { toast } from 'react-toastify';
import { isEmail } from 'validator';

import { functions, } from '../../firebase';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import classnames from 'classnames';

const resetCompanyUserPassword = functions.httpsCallable('resetCompanyUserPassword');
const { entries } = Object;
const fields = {
  email: {
    type: 'string',
    label: 'メールアドレス',
    validations: {
      required: v => !isEmpty(v),
      emailFormat: v => v && isEmail(v),
    },
  },
  password: {
    type: 'password',
    label: 'パスワード',
    validations: {
      required: v => !isEmpty(v),
    },
  },
};

export default function SignInForm(props) {
  const { companyId } = props;
  const [showsNewPasswordForm, toggleNewPasswordForm] = useToggle();
  const [hasSentResetMail, toggleSentResetMail] = useToggle();
  const [isSendingMail, toggleSendingMail] = useToggle();
  const statedFields = useFormState({}, showsNewPasswordForm ? pick(fields, 'email') : fields);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({ ...mapValues(statedFields, 'value') });
  };
  const onSubmitNewPassword = async (event) => {
    toggleSendingMail();
    event.preventDefault();
    if(isUnsubmittable) return;
    try {
      const { data: { error } = {} } = await resetCompanyUserPassword({ companyId, email: statedFields.email.value });
      if(error) {
        const message = ({
          'not-found': 'ユーザーが見つかりません',
        })[error.code] || '失敗しました';
        toast.error(message);
        toggleSendingMail();
        return;
      }

      toggleSentResetMail();
    } catch(e) {
      toast.error('失敗しました');
    }
    toggleSendingMail();
  };

  return !showsNewPasswordForm ? (
    <Form onSubmit={onSubmit}>
      <table>
        <tbody>
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <Field
              key={fieldName}
              name={fieldName}
              {...fieldSetting}
            />
          ))
        }
        </tbody>
      </table>
      <div className="login__box__btn">
        <Button type="submit" className={classnames('button -primary',{'-inactive':isUnsubmittable})} disabled={isUnsubmittable}>
          <span class="txt">ログイン</span>
        </Button>
      </div>
      <p class="login__txt">
        パスワードをお忘れの方は
        <a onClick={toggleNewPasswordForm}>
          こちら
        </a>
      </p>
    </Form>
  ) : (
    !hasSentResetMail ? (
      <Form onSubmit={onSubmitNewPassword}>
        <div className="mb-4">
          パスワードの再設定URLをメールにてご連絡します。
        </div>
        <table>
          <tbody>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => (
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
              />
            ))
          }
          </tbody>
        </table>
        <div class="login__box__btn">
          <Button type="submit" className={classnames('button -primary',{'-inactive':isUnsubmittable || isSendingMail})} disabled={isUnsubmittable || isSendingMail}>
            <span class="txt">メールを送信する</span>
          </Button>
        </div>
      </Form>
    ) : (
      <div>
        パスワードの再設定URLをメールにてお送りしました。
      </div>
    )
  );
};

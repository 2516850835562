import { useState, useEffect } from 'react';

export default function useDocumentSubscription (ref, dependencies = []) {
  const [item, setItem] = useState();
  useEffect(() => {
    if(!ref) return;
    const unsubscribe = ref
      .onSnapshot((doc) => {
        if(!doc.exists) return;
        setItem({ id: doc.id, ref: doc.ref, ...doc.data() });
      });
    return unsubscribe;
  }, dependencies);
  return item;
};

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import qs from 'qs';
import { useToggle } from 'react-use';
import { isUndefined, omitBy } from 'lodash';

import firebase, { functions } from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useFunctionsReturn from '../hooks/useFunctionsReturn';
import NewPasswordForm from '../forms/NewPasswordForm';
import Logo from '../../sass/img/mark.png';

const auth = firebase.auth();
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const setCompanyUserPassword = functions.httpsCallable('setCompanyUserPassword');
const getCompany = functions.httpsCallable('getCompany');

export default function CompanyNewPassword(props) {
  const { location: { search }, history, match: { params: { companyId } } } = props;
  const [isSubmitting, toggleSubmitting] = useToggle();
  const [hasSet, toggleHasSet] = useToggle(false);
  const [company, setCompany] = useState(undefined);
  const { invitationToken, newPasswordToken, } = qs.parse(search.slice(1));
  useFunctionsReturn(() => getCompany({ companyId }), setCompany);

  useEffect(() => {
    return auth.onAuthStateChanged((firebaseUser) => {
      if (firebaseUser != null) {
        history.push(`/companies/${companyId}`);
        if(hasSet) {
          toast.success('パスワードを設定し、ログインしました');
        } else {
          toast.error('すでにログイン済みです');
        }
      }
    });
  }, [hasSet]);

  const signIn = async ({ email, password }) => {
    try {
      await auth.signInWithEmailAndPassword(email, password);
    } catch(e) {
      const message = ({
        'auth/invalid-credential': 'メールアドレスかパスワードが間違っています',
      })[e.code] || 'ログインできませんでした';
      toast.error(message);
      console.error(e);
    }
  };

  const onSubmit = async (values) => {
    if(isSubmitting) return;

    toggleSubmitting(true);
    try {
      const { data: { email } } = await setCompanyUserPassword(omitBy({ companyId, invitationToken, newPasswordToken, ...values }, isUndefined));
      toggleHasSet(true);
      await signIn({ email, ...values });
    } catch(e) {
      toast.error(e.message);
      console.error(e);
    }
    toggleSubmitting(false);
  };

  return company != null && (
    <div className="login">
      <header className="login__header">
        <span className="login__header__mark">
          <img src={Logo} alt="リファ楽" />
        </span>
        <span className="login__header__txt">リファ楽 ログイン</span>
      </header>
      <main>
        <div className="login__box">
          <NewPasswordForm onSubmit={onSubmit} disabled={isSubmitting} />
        </div>
      </main>
    </div>
  );
};

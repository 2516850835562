const { isEmpty } = require('lodash');
const { isEmail } = require('validator');

const { userRoles } = require('../config');
const { entries } = Object;

module.exports = {
  fields: {
    displayName: {
      label: 'ユーザー名',
      type: 'string',
      validations: { required:  v => !isEmpty(v) }
    },
    email: {
      label: 'メールアドレス',
      type: 'string',
      validations: {
        required:  v => !isEmpty(v),
        emailFormat: v => v && isEmail(v)
      },
      readOnly: (v, { values, isNew }) => !isNew && !isEmpty(values.email),
      readOnlyNote: '※メールアドレスは編集できません'
    },
    role: {
      label: '権限',
      type: 'select',
      options: entries(userRoles).map(([k, v]) => ({ value: k, label: v })).reverse()
    },
    shopIds: {
      label: '所属',
      type: 'select',
      isMulti: true,
      options: []
    }
  }
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class AdminSidebarNav extends Component {
  render() {
    const menus = [
        {title: '管理者一覧', icn:'account_circle', to: '/admin/users'},
        {title: '会社一覧', icn:'business', to: '/admin/companies'},
    ];
    return (
        <div className="contents__nav">
        <nav className="nav">
          <ul className="nav__list">
            { 

            menus.map((menu,i) => {
                const has_active = (menu.to === this.props.location.pathname);
                return (
                <li key={i} className="nav__list__item">
                    <Link to={menu.to} className={['nav-link','nav__list__link', (has_active) ? '-active': ''].join(' ')}>
                        <span className="material-icons icn">{menu.icn}</span>
                        <span className="txt">{menu.title}</span>
                    </Link>
                </li>
                );
            })

            }
          </ul>
        </nav>
      </div>

    );
  }
}

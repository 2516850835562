const { isHiragana } = require('wanakana');
const { isEmpty } = require('lodash');
const { addHours, addDays, startOfDay } = require('date-fns');

const genders = {
  male: '男性',
  female: '女性',
  unknown: '選択しない',
};

const currentYear = (new Date()).getFullYear();
const fullname = _ => [_.lastName, _.firstName].join(' ');
const fullKana = _ => [_.lastNameKana, _.firstNameKana].join(' ');

const formatTel = (inputValue) => {
  const telHalfSizeCharacter = (inputValue || '').replace(/[０-９]/g, (s) => {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });

  return telHalfSizeCharacter.replace(/[^0-9]/g, () => {
    return '';
  });
};

const isTel = (inputValue) => {
  const tel = formatTel(inputValue);
  const regExpTel = new RegExp(/^0[0-9]{9,10}$/);
  return (regExpTel.test(tel));
};

module.exports = {
  fields: ({ shops = [], initialShopId } = {}) => {

    return {
      shopId: {
        type: 'categorized_select',
        label: '応募先',
        validations: {
          required: v => !isEmpty(v),
        },
        options: shops.filter(v => v.isEntry === 'ON').map(_ => ({ label: _.name, value: _.id, category_label: _.area_name})),
        initialValue: initialShopId,
        placeholder: '検索または選択する',
        category_placeholder: '応募先を絞り込む',
      },
      lastName: {
        type: 'string',
        label: '氏（漢字）',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      firstName: {
        type: 'string',
        label: '名（漢字）',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      lastNameKana: {
        type: 'string',
        label: '氏（かな）',
        validations: {
          required: v => !isEmpty(v),
          hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
        },
      },
      firstNameKana: {
        type: 'string',
        label: '名（かな）',
        validations: {
          required: v => !isEmpty(v),
          hiragana: v => isHiragana((v || '').replace(/[ 　]/g, '')),
        },
      },
      birthday: {
        type: 'date',
        label: '生年月日',
        selector: true,
        yearRange: [currentYear - 80, currentYear - 15],
        initialValue: new Date((new Date()).getFullYear() - 18, 0, 1),
        validations: {
          required: v => !!v,
        },
      },
      gender: {
        type: 'radio',
        label: '性別',
        options: Object.entries(genders).map(([k, v]) => ({ label: v, value: k })),
      },
      phone: {
        type: 'string',
        inputType: 'tel',
        label: '電話番号',
        filter: v => formatTel(v),
        validations: {
          required: v => !isEmpty(v),
          tel: v => isTel(v),
        },
      },
      email: {
        type: 'string',
        inputType: 'email',
        label: 'メールアドレス',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      postalCode: {
        type: 'string',
        inputType: 'tel',
        label: '郵便番号',
        validations: {
          required: v => !isEmpty(v),
          format: v => (v || '').match(/^[0-9]{7}$/g),
        },
      },
      prefecture: {
        type: 'string',
        label: '住所（都道府県）',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      city: {
        type: 'string',
        label: '住所（市区町村＆町名）',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      address1: {
        type: 'string',
        label: '住所（番地）',
        validations: {
          required: v => !isEmpty(v),
        },
      },
      address2: {
        type: 'string',
        label: '住所（建物名＆部屋番号）',
      },
      note: {
        type: 'text',
        label: 'メモ',
        placeholder: 'ご不明点等ございましたらご記入ください。',
      },
    };
  },
  settingFields: {
    interviewAt: {
      type: 'datetime',
      label: '面接日時',
      initialValue: addHours(startOfDay(addDays(new Date(), 1)), 10),
      validations: {
        required: v => !!v,
      },
    },
    internalNote: {
      type: 'text',
      label: '内部メモ',
    },
  },
  fullname,
  fullKana,
  fullnameWithKana: _ => `${fullname(_)}(${fullKana(_)})`,
};

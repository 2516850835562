import React from 'react';

import CompanyPage from '../hocs/CompanyPage';

export default CompanyPage(function CompanyRoot(props) {

  return (
    <div className="contents__main">
        <main className="main">
          <div className="pageTitle">
            <h1 className="heading1">ようこそ！</h1>
          </div>
          
          <div className="info">
            <p className="info__txt">
              適切な手順に従い、企業設定を実施してください。
            </p>
          </div>
        </main>
      </div>
  );
});

import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import qs from 'qs';

import firebase from '../../firebase';
import AdminHeaderNav from '../AdminHeaderNav';
import AdminSidebarNav from '../AdminSidebarNav';
import Logo from '../../sass/img/mark.png';

const auth = firebase.auth();
const db = firebase.firestore();
const usersRef = db.collection('users');
const invitationsRef = db.collection('invitations');

export default function AdminPageHOC(WrappedComponent) {
  return class AdminPage extends Component {
    constructor() {
      super();
      this.state = {};
    }
    componentWillMount() {
      auth.onAuthStateChanged((firebaseUser) => {
        if (!firebaseUser) {
          auth.signOut();
          this.setState({ uid: null, email: null, displayName: null });
          this.openLoginModal();
          return;
        }
        const { email, uid, displayName } = firebaseUser;
        this.processAdminInvitation({ uid, email, displayName });
        this.setState({ uid, email, displayName, firebaseUser });
      });
    }
    async processAdminInvitation({ uid, email, displayName }) {
      const { history, location, } = this.props;
      const { location: { search = '' } } = this.props;
      const { adminInvitationToken } = qs.parse(search.slice(1));
      if(!adminInvitationToken) return;
      const { exists, ref } = await invitationsRef.doc(adminInvitationToken).get();
      if(!exists) {
        toast.error('Invalid invitation token.');
        return history.replace(location.pathname);
      }
      await ref.update({ uid, email, displayName });
      toast.success('招待を承認しました');
    }
    signInWithProvider = (providerName) => {
      const provider = new firebase.auth[`${providerName}AuthProvider`]();
      auth.signInWithRedirect(provider);
    }
    openLoginModal() {
      this.setState({ shouldShowLoginForm: true });
    }
    onSetUid() {
      this.listenUser();
      this.updateUser();
    }
    listenUser() {
      const { uid } = this.state;
      usersRef
        .doc(uid)
        .onSnapshot(_ => this.setState({ user: _.data() }));
    }
    async updateUser() {
      const { uid, email, displayName } = this.state;
      const { ref, exists } = await usersRef.doc(uid).get();
      if(!exists) return;
      ref.update({ uid, email, displayName, });
    }
    componentDidUpdate(prevProps, prevState) {
      if (this.state.uid && !prevState.uid) {
        this.onSetUid();
      }
    }
    render() {
      const { firebaseUser, user, shouldShowLoginForm = false } = this.state;
      return (
        <div>
          {
            firebaseUser ? (
              user ? (
                <div>
                  <AdminHeaderNav {...this.props} {...this.state} />
                  <div class="contents">
                  <AdminSidebarNav {...this.props} />
                  <WrappedComponent {...this.props} {...{ user }} />
                  </div>
                </div>
              ) : (
                <div>Not authorized. Please get admin invitation.</div>
              )
            ) : (
              shouldShowLoginForm && (
                <div className="login">
                <header className="login__header">
                  <span className="login__header__mark">
                    <img src={Logo} alt="リファ楽" />
                  </span>
                  <span className="login__header__txt">リファ楽 ログイン</span>
                </header>
                <main>

                <div class="login__box">
                <div class="login__box__btn">
                    <Button onClick={this.signInWithProvider.bind(this, 'Google')} block>
                      Googleでログイン
                    </Button>
                    </div>
                  </div>
                </main>
                </div>
              )
            )
          }
        </div>
      );
    }
  };
};

import React from 'react';
import { format as formatDate } from 'date-fns';
import { keyBy } from 'lodash';

import firebase from '../../firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import CompanyPage from '../hocs/CompanyPage';
import CompanyPeopleBenefits from '../modals/CompanyPeopleBenefits'
import CompanyPeopleShares from '../modals/CompanyPeopleShares'

import { fields } from '../../shared/models/people';
import numeral from 'numeral';

const { keys } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(function CompanyPeople(props) {
  const {
    user,
    match: {
      params: { companyId, peopleId },
    },
  } = props;

  const people = useDocumentSubscription(companiesRef.doc(companyId).collection('people').doc(peopleId), [companyId, peopleId]);
  const shops = useCollectionSubscription(companiesRef.doc(companyId).collection('shops'), [companyId]);
  const shopsById = keyBy(shops, 'id');
  if (people) {
    people.shopId = (shopsById[people.shopId] || {}).name || people.shopId;
  }
  const allFields = { ...fields };

  return (
    <div className="contents__main">
      <main className="main">
        <div className="pageTitle">
          <h1 className="heading1">社員詳細</h1>
        </div>
        <section className="main__section">
          <div className="table">
            {
              people && (
                <table>
                  <tbody>
                    {
                      Object.entries(allFields).map(([fieldName, { label, type }]) => {
                        const _value = people[fieldName];
                        const value = (({
                          date: () => _value && formatDate(_value.toDate(), 'yyyy/MM/dd'),
                          datetime: () => _value && formatDate(_value.toDate(), 'yyyy/MM/dd HH:mm'),
                        })[type] || (_ => _value))();

                        return (
                          <tr key={fieldName}>
                            <th>{label}</th>
                            <td style={{ whiteSpace: 'pre-line' }}>{value}</td>
                          </tr>
                        )
                      })
                    }
                    <tr>
                      <th>シェア数</th>
                      <td className="text-right">
                        {numeral((people.entryLineUserIds || []).length).format('0,0')}
                      </td>
                    </tr>
                  </tbody>
                </table>
              )
            }
          </div>
        </section>
        <CompanyPeopleShares companyId={companyId} peopleId={peopleId} />
        <CompanyPeopleBenefits companyId={companyId} peopleId={peopleId} />
      </main>
    </div>
  );
});

import React, { useState, } from 'react';
import { Button, Input } from 'reactstrap';
import { keyBy, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { format as formatDate, } from 'date-fns';
import { useToggle } from 'react-use';
import Select from 'react-select';

import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { confirm } from '../ConfirmModal';

const db = firebase.firestore();
const functions = firebase.app().functions('asia-northeast1');
const companiesRef = db.collection('companies');

export default function CompanyEntryChats(props){
  const { companyId, entryId } = props;

  const company = useDocumentSubscription(companiesRef.doc(companyId), [companyId]);
  const entry = useDocumentSubscription(companiesRef.doc(companyId).collection('entries').doc(entryId), [companyId, entryId]);

  const messageTemplates = useCollectionSubscription(companiesRef.doc(companyId).collection('messageTemplates').orderBy('createdAt'), [companyId]);
  const messageTemplatesById = keyBy(messageTemplates, 'id');
  const messageLogs = useCollectionSubscription(entry && companiesRef.doc(companyId).collection('friends').doc(entry.lineProfile.userId).collection('messageLogs').orderBy('createdAt'), [companyId, entry]);
  const [isSending, toggleSending] = useToggle();
  const [newMessage, setNewMessage] = useState('');
  const [selectedMessageTemplateId, setSelectedMessageTemplateId] = useState(null);
  const messageTemplateOptions = messageTemplates.map(_ => ({ label: _.name, value: _.id }));
  const onClickSendNewMessage = async () => {
    if(!(await confirm('メッセージを送る', (
      <div>
        <div style={{ whiteSpace: 'pre-line' }}>
          {newMessage}
        </div>
        <p>この内容で送信します。よろしいですか？</p>
      </div>
    )))) return;
    toggleSending(true);
    const sendLineMessage = functions.httpsCallable('sendLineMessage');
    try {
      await sendLineMessage({ companyId, entryId, message: newMessage });
      setNewMessage('');
    } catch(e) {
      console.error(e);
      toast.error('送信失敗しました');
    }
    toggleSending(false);
  };
  const onClickApplyMessageTemplate = () => {
    const messageTemplate = messageTemplatesById[selectedMessageTemplateId];
    if(!messageTemplate) return;
    setNewMessage(messageTemplate.body);
  }

  return (
    <section className="main__section">
        <div className="chat">
        {
            messageLogs.map((messageLog) => {
            const { id, text, sentBy, createdAt } = messageLog;
            const time = (
                <div className="time">
                {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                </div>
            );
            return (
                <div key={id}>
                {
                    ({
                    friend: () => (
                        <div className="friend-comment">
                        <div>
                            <div className="balloon">
                            {text}
                            </div>
                            {time}
                        </div>
                        </div>
                    ),
                    account: () => (
                        <div className="account-comment">
                        <div >
                            <div className="balloon">
                            {text}
                            </div>
                            {time}
                        </div>
                        </div>
                    )
                    })[sentBy]()
                }
                </div>
            );
            })
        }
      </div>
      <h2 className="heading2">メッセージを送る</h2>
      <div className="buttons -line">
        <div style={{ width: 300 }}>
            <Select
            options={messageTemplateOptions}
            value={messageTemplateOptions.find(_ => _.value === selectedMessageTemplateId) || null}
            onChange={_ => setSelectedMessageTemplateId(_.value)}
            placeholder="メッセージテンプレートを選択"
            />
        </div>
        <Button className="button -primary" disabled={selectedMessageTemplateId == null} onClick={onClickApplyMessageTemplate} style={{minWidth: 0,height: 65}}>
            <span className="txt">挿入</span>
        </Button>
      </div>
      <Input type="textarea" rows={10} value={newMessage} onChange={_ => setNewMessage(_.target.value)} disabled={isSending} />
      <div className="form__buttons">
        <Button  onClick={onClickSendNewMessage} className={classnames('button -primary', { '-inactive':isSending})} disabled={isSending}>
            <span className="material-icons icn">{(isSending)? 'autorenew':'send'}</span>
            <span className="txt">送信</span>
        </Button>
      </div>

    </section>
  );
};

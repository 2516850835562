import React, { Fragment } from 'react';
import { Button } from 'reactstrap';
import { get, omit } from 'lodash';
import { format as formatDate } from 'date-fns';
import classnames from 'classnames';
import { useToggle } from 'react-use';

import sanitizeHtml from 'sanitize-html';
import firebase from '../../firebase';
import env from '../../env';
import { customItemTypes, webhookSendingLogStatuses } from '../../shared/config';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import CompanySettingsFormModal from '../modals/CompanySettingsFormModal';
import EntryFormSettingsFormModal from '../modals/EntryFormSettingsFormModal';
import CompanyPage from '../hocs/CompanyPage';
import CopyButton from '../CopyButton';
import EditButton from '../EditButton';
import { settingFields as companySettingsFields,initialMessageForShareDescription,initialEntryPrivacyPolicyContent } from '../../shared/models/company';
import { fields as _entryFields } from '../../shared/models/entry';

const { entries } = Object;
const projectId = env('FIREBASE_PROJECT_ID');
const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(function CompanySettings(props) {
  const { company, adminUser, match: { params: { companyId } } } = props;
  const entryFields = omit(_entryFields(), 'shopId');
  const webhookSendingLogs = useCollectionSubscription(companiesRef.doc(companyId).collection('webhookSendingLogs').orderBy('createdAt', 'desc').limit(50), [companyId]);
  const [showsEntryFormSettingsFormModal, toggleEntryFormSettingsFormModal] = useToggle();
  const lineWebhookUrl = `https://asia-northeast1-${projectId}.cloudfunctions.net/webhookLINE?companyId=${companyId}`;
  const authenticationLiffEndpointUrl = `${window.location.origin}/c/${companyId}/integrateLINE`;
  const entryLiffEndpointUrl = `${window.location.origin}/c/${companyId}/entry`;
  const joinLiffEndpointUrl = `${window.location.origin}/c/${companyId}/join`;

  return (
    <div className="contents__main">
    <main className="main">
      <div className="pageTitle">
        <h1 className="heading1">
        各種設定
        </h1>
      </div>
      {
        company && (
          <Fragment>
            <section className="main__section">
              <h2 className="heading2">設定</h2>
              <div className="buttons -line">
                <EditButton itemRef={companiesRef.doc(companyId)} FormModal={CompanySettingsFormModal} adminUser={adminUser} />
              </div>
              <div className="table">
                <table>
                  <tbody>
                  {
                    !!adminUser && (
                    <>
                      <tr>
                        <th>LINE Webhook URL</th>
                        <td>
                          {lineWebhookUrl}
                          <CopyButton content={lineWebhookUrl}/>
                        </td>
                      </tr>
                      <tr>
                        <th>LINE公式アカウントID</th>
                        <td>
                          <div title={company.lineAccountId}>
                            {company.lineAccountId}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>チャネルアクセストークン</th>
                        <td>
                          <div title={company.channelAccessToken}>
                            {company.channelAccessToken}
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>認証LIFFエンドポイントURL</th>
                        <td>
                          {authenticationLiffEndpointUrl}
                          <CopyButton content={authenticationLiffEndpointUrl}/>
                        </td>
                      </tr>
                      <tr>
                        <th>認証LIFF URL</th>
                        <td>
                          {company.authenticationLiffUrl}
                        </td>
                      </tr>
                      <tr>
                        <th>認証時に氏名を表示する</th>
                        <td>
                          {({ true: 'YES', false: 'NO' })[company.authenticationLiffNameEnabled || false]}
                        </td>
                      </tr>
                      <tr>
                        <th>認証時に所属を表示する</th>
                        <td>
                          {({ true: 'YES', false: 'NO' })[company.authenticationLiffShopIdEnabled || false]}
                        </td>
                      </tr>
                      <tr>
                        <th>認証時に従業員番号を表示する</th>
                        <td>
                          {({ true: 'YES', false: 'NO' })[company.authenticationLiffEmployeeNumberEnabled || false]}
                        </td>
                      </tr>
                      <tr>
                        <th>認証時に生年月日を表示する</th>
                        <td>
                          {({ true: 'YES', false: 'NO' })[company.authenticationLiffBirthDayEnabled || false]}
                        </td>
                      </tr>
                      <tr>
                        <th>応募LIFFエンドポイントURL</th>
                        <td>
                          {entryLiffEndpointUrl}
                          <CopyButton content={entryLiffEndpointUrl}/>
                        </td>
                      </tr>
                      <tr>
                        <th>応募LIFF URL</th>
                        <td>
                          {company.entryLiffUrl}
                        </td>
                      </tr>
                    </>
                    )
                    }
                    <tr>
                      <th>{companySettingsFields.authenticationLiffPageTitle.label}</th>
                      <td>
                        {company.authenticationLiffPageTitle || companySettingsFields.authenticationLiffPageTitle.initialValue}
                      </td>
                    </tr>
                    <tr>
                      <th>{companySettingsFields.entryLiffPageTitle.label}</th>
                      <td>
                        {company.entryLiffPageTitle || companySettingsFields.entryLiffPageTitle.initialValue}
                      </td>
                    </tr>
                    <tr>
                      <th>Webhook URL</th>
                      <td>
                        {company.webhookUrl}
                      </td>
                    </tr>
                    <tr>
                      <th>シェア方法の説明</th>
                      <td>
                        {company.messageForShareDescription || initialMessageForShareDescription}
                      </td>
                    </tr>
                    <tr>
                      <th>シェア用メッセージ</th>
                      <td>
                        {company.messageForShare}
                      </td>
                    </tr>
                    <tr>
                      <th>応募完了時にメッセージを送信する</th>
                      <td>
                        {({ true: 'YES', false: 'NO' })[company.messageAtEntryEnabled || false]}
                      </td>
                    </tr>
                    <tr>
                      <th>応募完了時の紹介者へのメッセージ</th>
                      <td>
                        {company.messageAtEntryForReferrer}
                      </td>
                    </tr>
                    <tr>
                      <th>応募完了時の被紹介者へのメッセージ</th>
                      <td>
                        {company.messageAtEntryForApplicant}
                      </td>
                    </tr>
                    <tr>
                      <th>応募ページコンテンツ</th>
                      <td>
                        <div title={company.channelAccessToken}>
                          <div dangerouslySetInnerHTML={
                            { __html: sanitizeHtml(
                                company.entryPageContent, 
                                { allowedTags: [...sanitizeHtml.defaults.allowedTags, 'h2'] }
                              ) 
                            }
                            } />
                          
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>個人情報の取扱い</th>
                      <td>
                        <div dangerouslySetInnerHTML={
                              { __html: sanitizeHtml(
                                company.entryPrivacyPolicyContent || initialEntryPrivacyPolicyContent, 
                                  { allowedTags: [...sanitizeHtml.defaults.allowedTags, 'h2'] }
                                ) 
                              }
                              } />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>

            <section className="main__section">
              <h2 className="heading2">応募フォーム設定</h2>
              <div className="buttons -line">
                <Button className="button -primary" onClick={toggleEntryFormSettingsFormModal}>
                  <span className="material-icons icn">edit</span>
                  <span className="txt">編集</span>
                </Button>
              </div>
              <div className="table">
                基本項目
                <table>
                  <thead>
                    <tr>
                      <th>項目名</th>
                      <th>表示</th>
                      <th>webhook送信</th>
                      <th>key</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      entries(entryFields).map(([fieldName, { label, }]) => {
                        const { [fieldName]: { enabled = true, webhook = true } = {} } = company.entryFormSettings || {};
                        return (
                          <tr key={fieldName}>
                            <th>
                              {label}
                            </th>
                            <td style={{ width: 150 }} className={classnames({ 'text-success': enabled, 'text-muted small': !enabled })}>
                              {({ true: 'ON', false: 'OFF' })[enabled]}
                            </td>
                            <td style={{ width: 150 }} className={classnames({ 'text-success': webhook, 'text-muted small': !webhook })}>
                              {
                                enabled && (
                                  <span>{({ true: 'ON', false: 'OFF' })[webhook]}</span>
                                )
                              }
                            </td>
                            <td style={{ width: 150 }}>
                              {
                                enabled && webhook && (
                                  <span>{fieldName}</span>
                                )
                              }
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
                カスタム項目
                <table>
                  <thead>
                    <tr>
                      <th>項目名</th>
                      <th>入力タイプ</th>
                      <th>選択肢</th>
                      <th>必須</th>
                      <th>webhook送信</th>
                      <th>key</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      (get(company, 'entryFormSettings.customItems') || []).map(({ description, type, optionsString, isRequired, webhook }, i) => {
                        return (
                          <tr key={i}>
                            <th>
                              {description}
                            </th>
                            <td>
                              {customItemTypes[type]}
                            </td>
                            <td>
                              {(optionsString || '')}
                            </td>
                            <td>
                              {isRequired && 'YES'}
                            </td>
                            <td className={classnames({ 'text-success': webhook, 'text-muted small': !webhook })}>
                              {({ true: 'ON', false: 'OFF' })[webhook]}
                            </td>
                            <td>
                              {
                                webhook && (
                                  <span>{description}</span>
                                )
                              }
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </section>

            <section className="main__section">

              <h2 className="heading2">Webhook送信ログ</h2>
              <div className="table">
                        
                <table>
                  <thead>
                    <tr>
                      <th>日時</th>
                      <th>状態</th>
                      <th>URL</th>
                      <th>内容</th>
                      <th>エラーメッセージ</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      webhookSendingLogs.map((webhookSendingLog) => {
                        const { id, status, url, data, createdAt, errorMessage, } = webhookSendingLog;
                        return (
                          <tr key={id} className={classnames({ 'table-success': status === 'successed', 'table-danger': status === 'failed' })}>
                            <td>{formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}</td>
                            <td>{webhookSendingLogStatuses[status]}</td>
                            <td style={{ maxWidth: 250, wordBreak: 'break-all' }}>{url}</td>
                            <td style={{ maxWidth: 250, wordBreak: 'break-all' }}>{JSON.stringify(data)}</td>
                            <td style={{ maxWidth: 250, wordBreak: 'break-all' }}>{errorMessage}</td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
            </section>
          </Fragment>
            )
          }

      {
        showsEntryFormSettingsFormModal && (
          <EntryFormSettingsFormModal isOpen company={company} onClickClose={toggleEntryFormSettingsFormModal} />
        )
      }
      </main>
    </div>
  );
});

import React, { useState } from 'react';
import fileDownload from 'js-file-download';
import { TextEncoder } from 'text-encoding';
import { unparse as unparseCsv } from 'papaparse';
import classnames from 'classnames';
import { Button } from 'reactstrap';

export default function ExportButton({
  fileName,
  rows,
  fields,
  ...extraProps
}) {
  const [isExporting, setIsExporting] = useState(false);

  const onClickExport = () => {
    setIsExporting(true);
    const encoder = new TextEncoder('Shift_JIS', {
      NONSTANDARD_allowLegacyEncoding: true,
    });
    const fileContent = encoder.encode(
      unparseCsv(fields ? { fields, data: rows } : rows)
    );
    fileDownload(fileContent, fileName);
    setIsExporting(false);
  };

  return (
    <Button
      className={classnames('button -ghost',{'-inactive':isExporting})}
      onClick={onClickExport}
      disabled={isExporting}
      {...extraProps}
    >

      <span className="material-icons icn">publish</span>
      <span className="txt">エクスポート</span>
    </Button>
  );
}

import React, { useState, } from 'react';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';

import useDocumentSubscription from './hooks/useDocumentSubscription';

export default function EditButton ({ itemRef, FormModal, formProps, adminUser = undefined,onlyIconButton = false, ...extraProps }) {
  const [showsModal, setShowsModal] = useState(false);
  const item = useDocumentSubscription(showsModal && itemRef, [showsModal]);
  const onSubmit = async (values) => {
    try {
      await itemRef.update(values);
      toast.success('保存しました');
      setShowsModal(false);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <>
    {
    (onlyIconButton) ?
      (
        <Button onClick={_ => setShowsModal(true)} {...extraProps}>
          <span className="material-icons icn">edit</span>
        </Button>
      ) : (
        <Button  className="button -primary" onClick={_ => setShowsModal(true)} {...extraProps}>
          <span className="material-icons icn">edit</span>
          <span className="txt">編集</span>
        </Button>
      )
    }
    {
      item && (
        <FormModal isOpen={showsModal} values={item} onClickClose={_ => setShowsModal(false)} onSubmit={onSubmit} {...formProps} adminUser={adminUser} />
      )
    }
    </>
  );
};

const { isEmpty } = require('lodash');

const entryOption = [
  { label: 'ON', value: 'ON' },
  { label: 'OFF', value: 'OFF' }
]

module.exports = {
  fields: {
    area_name: {
      label: 'エリア名',
      type: 'string',
      validations: { required:  v => !isEmpty(v) }
    },
    id: {
      label: '応募先ID',
      type: 'string',
      validations: { required:  v => !isEmpty(v) },
      readOnly: (v, { values, isNew }) => !isNew && !isEmpty(values.id),
      readOnlyNote: '※応募先IDは編集できません'
    },
    name: {
      label: '応募先名',
      type: 'string',
      validations: { required:  v => !isEmpty(v) }
    },
    isEntry: {
      label: '応募',
      type: 'radio',
      validations: { required:  v => v === 'ON' || v === 'OFF' },
      options: entryOption
    }    
  }
};

import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';

import { sortBy, pick, keyBy, isUndefined, clone } from 'lodash';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import dedent from 'dedent';
import { format as formatDate } from 'date-fns';

import firebase from '../../firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import CompanyPage from '../hocs/CompanyPage';
import ExportButton from '../ExportButton';
import DeleteButton from '../DeleteButton';
import EditButton from '../EditButton';

import CompanyImportFormModal from '../modals/CompanyImportFormModal';

import { fields } from '../../shared/models/people';

const { keys } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(function CompanyPeoples(props) {
  const {
    user,
    adminUser,
    match: {
      params: { companyId },
    },
  } = props;
  const isAdmin = !!adminUser || user.role === 'admin';
  const csvFields = ['employeeNumber', 'name', 'shopId', 'birthday','joinedAt', 'createdAt', 'shares','lineId'];
  const allPeople = useCollectionSubscription(
    isAdmin &&
      companiesRef
        .doc(companyId)
        .collection('people')
        .orderBy('createdAt', 'desc'),
    [companyId]
  );
  const limitedPeople = useCollectionsFetch(
    !isAdmin &&
      keys(user.shopIds || {}).map((_) =>
        companiesRef
          .doc(companyId)
          .collection('people')
          .where('shopId', '==', _)
          .orderBy('createdAt', 'desc')
      ),
    [companyId]
  );
  const people = isAdmin ? allPeople : limitedPeople;
  const sortedPeople = sortBy(people, (_) => !_.createdAt.toDate());
  const shops = useCollectionSubscription(
    companiesRef.doc(companyId).collection('shops').orderBy('index', 'asc'),
    [companyId]
  );
  const shopsById = keyBy(shops, 'id');
  fields.shopId.options = shops.map(_ => ({ label: _.name, value: _.id, category_label: _.area_name }));

  return (
    <div className="contents__main">
      <main className="main">
        <div className="pageTitle">
          <h1 className="heading1">登録者一覧</h1>
        </div>
        <section className="main__section">          
          {sortedPeople.length > 0 ? (
            <>
              <div className="buttons -line">
              {isAdmin && (
                <>
                  <ExportButton
                    fileName="登録者一覧.csv"
                    fields={csvFields}
                    rows={sortedPeople
                      .map((_) => {
                        const clonePeple = clone(_)
                        if (clonePeple.joinedAt && !!clonePeple.joinedAt.toDate)
                          clonePeple.joinedAt = formatDate(
                            clonePeple.joinedAt.toDate(),
                            'yyyy/MM/dd'
                          );
                        if (clonePeple.createdAt && !!clonePeple.createdAt.toDate)
                          clonePeple.createdAt = formatDate(
                            clonePeple.createdAt.toDate(),
                            'yyyy/MM/dd'
                          );
                        if (clonePeple.birthday && !!clonePeple.birthday.toDate)
                          clonePeple.birthday = formatDate(
                            clonePeple.birthday.toDate(),
                            'yyyy/MM/dd'
                          );
                          clonePeple.shares = (clonePeple.entryLineUserIds) ? numeral(clonePeple.entryLineUserIds.length).format('0,0') : 0;
                          clonePeple.lineId = (clonePeple.lineProfile || {}).userId;
                        return clonePeple;
                      })
                      .map((_) => pick(_, csvFields))
                    }
                  />
                </>
              )}
              </div>
              <div className="table scroll-x">
                <table>
                  <thead>
                    <tr>
                      <th></th>
                      <th>従業員番号</th>
                      <th>名前</th>
                      <th>所属</th>
                      <th>生年月日</th>
                      <th>入社日</th>
                      <th>登録日</th>
                      <th className="u-w10">
                        自分で開く
                        <div className="tooltip upsidedown">
                          <span className="material-icons icn">help_outline</span>
                          <div className="tooltip__inner">
                            <p className="tooltip__balloon">紹介者が発行したシェア用URLを紹介者自身が開くとチェックがつきます</p>
                          </div>
                        </div>
                      </th>
                      <th className="u-w10">
                        シェア数
                        <div className="tooltip upsidedown">
                          <span className="material-icons icn">help_outline</span>
                          <div className="tooltip__inner">
                            <p className="tooltip__balloon">紹介者が発行したシェア用URLにアクセスした人数です</p>
                          </div> 
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedPeople.map((person) => {
                      const {
                        id,
                        employeeNumber,
                        name,
                        shopId,
                        birthday,
                        lineProfile,
                        selfOpenEntry = false,
                        entryLineUserIds = [],
                        joinedAt,
                        ref,
                        createdAt,
                      } = person;
                      const shop = shopsById[shopId];

                      return (
                        <tr key={id}>
                          <td>
                            <Link to={`/companies/${companyId}/peoples/${id}`}>
                              詳細
                            </Link>
                          </td>
                          <td>{employeeNumber}</td>
                          <td>{name}</td>
                          <td>{shop ? shop.name : shopId}</td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {birthday && !!birthday.toDate
                              ? formatDate(birthday.toDate(), 'yyyy/MM/dd')
                              : ''}
                          </td>
                          <td style={{ whiteSpace: 'nowrap' }}>
                            {joinedAt && !!joinedAt.toDate
                              ? formatDate(joinedAt.toDate(), 'yyyy/MM/dd')
                              : ''}
                          </td>
                          <td>
                            {createdAt && !!createdAt.toDate
                              ? formatDate(createdAt.toDate(), 'yyyy/MM/dd')
                              : ''}
                          </td>
                          <td>
                            {
                            selfOpenEntry && 
                              (<span className="material-icons icn">done</span>)
                            }
                          </td>
                          <td className="text-right">
                            {numeral(entryLineUserIds.length).format('0,0')}
                          </td>
                          <td>
                            <div className="edit">
                            {isAdmin && (
                              <>

                                <EditButton 
                                  itemRef={ref}
                                  FormModal={CompanyImportFormModal}
                                  onlyIconButton={true} 
                                  formProps={{fields: fields}} />
                                <DeleteButton itemRef={ref} icon='person_off' />
                              </>
                            )}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div>
              社員が未登録です
            </div>
          )}
        </section>
      </main>
    </div>
  );
});

import React, { Fragment, useState, } from 'react';
import { Link } from 'react-router-dom';
import { Button, Label, Input } from 'reactstrap';
import { isEqual, omitBy, orderBy, keyBy, } from 'lodash';
import classnames from 'classnames';
import { format as formatDate, subDays, } from 'date-fns';
import { useList, } from 'react-use';

import firebase from '../../firebase';
import { fields, settingFields } from '../../shared/models/entry';
import { entryStatuses } from '../../shared/config';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import CompanyPage from '../hocs/CompanyPage';
import ExportButton from '../ExportButton';

const { keys } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(function CompanyEntries(props) {
  const { user, adminUser, match: { params: { companyId } } } = props;
  const company = useDocumentSubscription(companiesRef.doc(companyId), [companyId]);
  const { entryActionWaitingDays = 7 } = company || {};
  const [sort, setSort] = useState({ field: 'createdAt', direction: 'desc', });
  const [selectedIds, { set: setSelectedIds, removeAt: removeSelectedIdAt, push: pushSelectedId }] = useList([]);
  const isAdmin = !!adminUser || user.role === 'admin';
  const [visibleStatuses, setVisibleStatuses] = useState(['waitingToFollow', 'entryCompleted', 'joined']);
  const allEntries = useCollectionSubscription(isAdmin && companiesRef.doc(companyId).collection('entries').orderBy('createdAt', 'desc'), [companyId]);
  const limitedEntries = useCollectionsFetch(!isAdmin && keys(user.shopIds || {}).map(_ => companiesRef.doc(companyId).collection('entries').where('shopId', '==', _).orderBy('createdAt', 'desc')), [companyId]);
  const entries = isAdmin ? allEntries : limitedEntries;
  const people = useCollectionSubscription(companiesRef.doc(companyId).collection('people'), [companyId]);
  const shops = useCollectionSubscription(companiesRef.doc(companyId).collection('shops'), [companyId]);
  const shopsById = keyBy(shops, 'id');
  const peopleByLineUserId = keyBy(people, 'lineProfile.userId');
  const onClickReject = async (ids) => {
    if(!window.confirm('本当に不採用にしますか？')) return;
    await updateStatus('rejected', ids);
    setSelectedIds([]);
  };
  const onClickJoin = async (ids) => {
    if(!window.confirm('本当に採用にしますか？')) return;
    await updateStatus('joined', ids);
    setSelectedIds([]);
  };
  const onClickRevert = async (ids) => {
    if(!window.confirm('本当に応募済みに戻しますか？')) return;
    await updateStatus('entryCompleted', ids);
    setSelectedIds([]);
  };
  const updateStatus = async (status, ids) => {
    await Promise.all(ids.map(id => 
      companiesRef.doc(companyId).collection('entries').doc(id).update({ status })
    ));
  };
  const { entryFormSettings = {} } = company || {};
  const { customItems = [] } = entryFormSettings;
  const baseFields = omitBy(
    fields(),
    (v, k) => (entryFormSettings[k] || {}).enabled === false,
  );
  const fieldsByCustomItems = customItems.reduce((x, { description, type, }) => {
    return {
      ...x,
      [description]: {
        label: description,
        type,
      },
    };
  }, {});
  const formFields = { ...settingFields, ...baseFields, ...fieldsByCustomItems, };
  const filteredEntries = entries.filter(_ => visibleStatuses.includes(_.status || 'waitingToFollow'));
  const sortedEntries = orderBy(filteredEntries, _ => _[sort.field] && _[sort.field].toDate(), sort.direction);
  const entriesWithInfo = sortedEntries.map((entry) => {
    const { shopId, referrerId, } = entry;
    const referrer = peopleByLineUserId[referrerId];
    const shop = shopsById[shopId];
    return {
      entry,
      referrer,
      shop,
    };
  });
  const entriesForExport = entriesWithInfo.map(({ entry, referrer, shop }) => {
    const { status, shopId, referrerId, lineProfile, createdAt, } = entry;

    return {
      status,
      statusName: entryStatuses[status],
      shopId,
      shopName: (shop || {}).name,
      referrerId,
      referrerName: (referrer || {}).name,
      lineUserId: lineProfile.userId,
      createdAt: formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss'),
      ...(
        Object.entries(formFields).reduce((x, [fieldName, { label, type }]) => {
          const _value = entry[fieldName];
          const value = (({
            date: () => _value && formatDate(_value.toDate(), 'yyyy/MM/dd'),
            datetime: () => _value && formatDate(_value.toDate(), 'yyyy/MM/dd HH:mm'),
          })[type] || (_ => _value))();
          return {
            ...x,
            [fieldName]: value,
          };
        }, {})
      )
    };
  });
  const onChangeVisibleStatus = (status, { target: { checked } }) => {
    setVisibleStatuses(checked ? [...visibleStatuses, status] : visibleStatuses.filter(_ => _ !== status));
  };
  const onClickSort = (field) => {
    setSort({
      field,
      direction: field === sort.field && sort.direction === 'asc' ? 'desc' : 'asc',
    });
  }
  const noActionEntries = entries.filter(_ => _.status === 'entryCompleted').filter(_ => _.createdAt.toDate() < subDays(new Date(), company.entryActionWaitingDays));

  return (
    <div className="contents__main">
      <main className="main">
        <div className="pageTitle">
          <h1 className="heading1">応募一覧</h1>
        </div>
          {
            noActionEntries.length > 0 && (
              <div className="info">
                <p className="info__txt">
                  応募後、{entryActionWaitingDays}日すぎてもステータスが変更されていない応募者がいます
                </p>
              </div>
            )
          }
          <section className="main__section">
          
            <div className="buttons -line">
              <ExportButton fileName="応募.csv" rows={entriesForExport} />
            </div>
            <div className="buttons -line">
                {
                  selectedIds.length === 0 ? ( 
                    <ul className="form__list">
                      {
                        Object.entries(entryStatuses).map(([status, statusText]) => {
                          return (
                            <li className="form__list__item" key={status}>
                              <Label check >
                                <Input type="checkbox" checked={visibleStatuses.includes(status)} onChange={onChangeVisibleStatus.bind(null, status)} />
                                {statusText}
                              </Label>
                            </li>
                          );
                        })
                      }
                    </ul>
                  ) : (
                    <Fragment>
                        <Button className="button -primary" onClick={onClickReject.bind(null, selectedIds)}>
                          <span className="material-icons icn">cancel</span>
                          <span className="txt">不採用</span>
                        </Button>
                        <Button className="button -primary" onClick={onClickJoin.bind(null, selectedIds)}>
                          <span className="material-icons icn">check_circle</span>
                          <span className="txt">採用</span>
                        </Button>
                        <Button className="button -secondary" onClick={onClickRevert.bind(null, selectedIds)}>
                          <span className="material-icons icn">replay_circle_filled</span>
                          <span className="txt">応募済みに戻す</span>
                        </Button>
                    </Fragment>
                  )
                }

            </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>
                    <Input
                      type="checkbox"
                      checked={isEqual(selectedIds, entriesWithInfo.map(_ => _.entry.id))}
                      onChange={_ => _.target.checked ? setSelectedIds(entriesWithInfo.map(_ => _.entry.id)) : setSelectedIds([])}
                    />
                  </th>
                  <th>状態</th>
                  <th>応募者名</th>
                  <th>応募先</th>
                  <th>紹介者</th>
                  <th className="u-w10">
                    <span onClick={onClickSort.bind(null, 'createdAt')}>
                      応募日時
                      <span  className="tooltip">
                        <span className="material-icons icn">
                          {
                          (sort.field === 'createdAt') && (sort.direction === 'asc' ? 'arrow_upward' : 'arrow_downward')
                          }
                        </span>
                      </span>
                    </span>
                  </th>
                  <th className="u-w10">
                    <span onClick={onClickSort.bind(null, 'interviewAt')}>
                      面接日時
                      <span  className="tooltip">
                        <span className="material-icons icn">
                          {
                          (sort.field === 'interviewAt') && (sort.direction === 'asc' ? 'arrow_upward' : 'arrow_downward')
                          }
                        </span>
                      </span>
                    </span>
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  entriesWithInfo.map(({ entry, referrer, shop }) => {
                    const { id, name, firstName, lastName, referrerId, createdAt, status = 'waitingToFollow', interviewAt } = entry;
                    const isSelected = selectedIds.includes(id);
                    return (
                      <tr key={id} id={id} className={classnames({ 'table-success': status === 'joined', 'table-dark': status === 'rejected' })}>
                        <td className="text-nowrap">
                          <Input
                            type="checkbox"
                            checked={isSelected}
                            className="m-0"
                            onChange={_ => _.target.checked ? pushSelectedId(id) : removeSelectedIdAt(selectedIds.indexOf(id))}
                          />
                        </td>
                        <td className="text-nowrap">
                          {entryStatuses[status]}
                        </td>
                        <td>
                          <Link to={`/companies/${companyId}/entries/${id}`}>
                            {name || `${lastName} ${firstName}`}
                          </Link>
                        </td>
                        <td>
                          {(shop || {}).name}
                        </td>
                        <td style={{ wordBreak: 'break-all', }}>
                          {
                            referrer ? (
                              <span>{referrer.name}</span>
                            ) : (
                              <span className="small text-muted">{referrerId}</span>
                            )
                          }
                        </td>
                        <td>
                          {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                        </td>
                        <td>
                          {interviewAt && formatDate(interviewAt.toDate(), 'yyyy/MM/dd HH:mm')}
                        </td>
                        <td>
                          <div style={(selectedIds.length > 0)?{visibility:'hidden'}:{} }>
                            {
                              status === 'entryCompleted' && (
                                <div className={classnames('edit')}>
                                  <Button onClick={onClickReject.bind(null, [id])}>
                                    <span className="tooltip">
                                      <span className="material-icons icn">cancel</span>
                                      <span className="tooltip__inner">
                                        <span className="tooltip__balloon">不採用にする</span>
                                      </span>
                                    </span>
                                  </Button>
                                  <Button onClick={onClickJoin.bind(null, [id])}>
                                    <span className="tooltip">
                                      <span className="material-icons icn">check_circle</span>
                                      <span className="tooltip__inner">
                                        <span className="tooltip__balloon">採用する</span>
                                      </span>
                                    </span>
                                  </Button>
                                </div>
                              )
                            }
                            {
                              ['rejected', 'joined'].includes(status) && (
                                <div className={classnames('edit')}>
                                  <Button onClick={onClickRevert.bind(null, [id])}>
                                  <span className="tooltip">
                                      <span className="material-icons icn">replay_circle_filled</span>
                                      <span className="tooltip__inner">
                                        <span className="tooltip__balloon">応募済みに戻す</span>
                                      </span>
                                    </span>
                                  </Button>
                                </div>
                              )
                            }
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </div>
  );
});

import React from 'react';
import { toast } from 'react-toastify';

export default function DeleteButton ({ itemRef, icon = 'delete', ...extraProps }) {
  const onClickDelete = async (props) => {
    if (!window.confirm('本当に削除しますか？')) return;
    await itemRef.delete();
    toast.success('削除しました');
  }

  return (
    <button onClick={onClickDelete} {...extraProps}>
      <span className="material-icons icn">{icon}</span>
    </button>
  );
};

import React from 'react';
import { Button, Modal, Form, } from 'reactstrap';
import { mapValues } from 'lodash';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { settingFields as fields, adminSettingFields as adminFields } from '../../shared/models/company';

const { entries } = Object;

//adminUserのみ表示させる項目
const showAdminUserOnlyitems = ['lineAccountId', 'channelAccessToken', 'authenticationLiffUrl', 'entryLiffUrl', 'joinLiffUrl'];

export default function CompanySettingsFormModal(props) {
  const { isOpen, values, admin = false, onClickClose, adminUser } = props;
  const isNew = !values;
  const statedFields = useFormState(values, (admin ? adminFields : fields), isOpen);

  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({ ...mapValues(statedFields, 'value') });
  };
  return (
    <Modal isOpen={isOpen}>
    <div class="modal__inner">
      <div class="modal__header">
        <h3 class="heading2">
        会社{isNew ? '追加' : '編集'}
        </h3>
        <div class="modal__close js-close-modal">
          <span class="material-icons icn" onClick={onClickClose}>close</span>
        </div>
      </div>
      <div class="modal__body">

      <Form onSubmit={onSubmit}>
      <table class="form__table">
        <tbody>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => {
              if(!adminUser && showAdminUserOnlyitems.includes(fieldName)) return <div />;
              return (
                <Field
                  key={fieldName}
                  name={fieldName}
                  {...fieldSetting}
                />
              )
            })
          }
        </tbody>
      </table>
      <div class="form__buttons">
        <Button className="button -secondary" onClick={onClickClose}>
          <span class="txt">キャンセル</span>
        </Button>
        <Button className={
          ['button','-primary', 
          (isUnsubmittable) ? '-inactive': ''].join(' ')
        } onClick={onSubmit} disabled={isUnsubmittable}>
          <span class="txt">保存</span>
        </Button>
      </div>
      </Form>
      </div>
    </div>
    </Modal>
  );
};

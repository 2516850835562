const { isEmpty, } = require('lodash');
const currentYear = (new Date()).getFullYear();

module.exports = {
  fields: ({ shops, readOnlyFlag }) => {
    return {
      name: {
        label: '名前',
        validations: {
          required: v => !isEmpty(v),
        },
        type: 'string',
        readOnly: (v) => readOnlyFlag,
      },
      shopId: {
        type: 'categorized_select',
        label: '所属',
        validations: {
          required: v => !isEmpty(v),
        },
        options: shops.map(_ => ({ label: _.name, value: _.id, category_label: _.area_name})),
        initialValue: null,
        placeholder: '検索または選択する',
        category_placeholder: '所属を絞り込む',
        readOnly: (v) => readOnlyFlag,
      },
      employeeNumber: {
        label: '従業員番号',
        validations: {
          required: v => !isEmpty(v),
        },
        type: 'string',
        readOnly: (v) => readOnlyFlag,
      },
      birthday: {
        type: 'date',
        label: '生年月日',
        selector: true,
        yearRange: [currentYear - 80, currentYear - 15],
        initialValue: new Date((new Date()).getFullYear() - 18, 0, 1),
        validations: {
          required: v => !!v,
        },
      },
    };
  },
};

import React, { Component } from 'react';
import AdminPage from '../hocs/AdminPage';

export default AdminPage(class AdminRoot extends Component {
  render () {
    return (
      <div class="contents__main">
        <main class="main">
          <div class="pageTitle">
            <h1 class="heading1">ようこそ！</h1>
          </div>
          
          <div class="info">
            <p class="info__txt">
              会社の基本情報の登録・変更・削除を行います。<br />
              適切な手順に従い、企業設定を実施してください。
            </p>
          </div>
        </main>
      </div>
    );
  }
});

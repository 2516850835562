import React from 'react';
import { Label, Input } from 'reactstrap';
import { flattenDeep, isEmpty, pick, omitBy, get, set, mapValues } from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';

import texts from '../../shared/texts';

const { entries, keys } = Object;
const validationText = (documentName, field, key) => {
  return (
    get(texts.validations[documentName], `${field}.${key}`)
    || get(texts.validations.general, key)
  );
}

export default function FormMixinHOC(WrappedComponent) {
  return class FormMixin extends WrappedComponent {
    _fields() {
      return (this.fields && this.fields()) || WrappedComponent.fields;
    }
    defaultState() {
      return {
        id: null,
        ...entries(this._fields()).reduce((x, [k, { type, defaultValue }]) => {
          return {
            ...x,
            [k]: defaultValue != null ? defaultValue : ({ string: '', boolean: false, integer: 0, select: null, datetime: '', date: '', time: '', })[type],
          };
        }, {}),
        isSubmitting: false,
      }
    }
    constructor() {
      super();
      this.state = { ...this.defaultState() };
    }
    clear() {
      this.setState({ ...this.defaultState() });
    }
    setValues() {
      const { values } = this.props;
      if(isEmpty(values)) return;
      const convertValuesDate = (values, fields) => {
        return mapValues(
          omitBy(values, _ => _ === undefined),
          (v, k) => {
            const { type, fields: childFields } = fields[k] || {};
            return (({
              datetime: () => v && v.toDate(),
              date: () => v && v.toDate(),
              time: () => v && v.toDate(),
              list: () => (v || []).map(_ => convertValuesDate(_, childFields)),
            })[type] || (_ => v))();
          }
        );
      };
      const mappedValues = convertValuesDate(values, this._fields());
      this.setState(mappedValues);
    }
    isValid = (name) => {
      return this.validationErrors(name).length === 0;
    }
    validationCss(name) {
      const isValid = this.isValid(name);
      return classnames({ 'is-valid': isValid, 'is-invalid': isValid === false });
    }
    isUnsubmittable() {
      const { isSubmitting } = this.state;
      return isSubmitting || !keys(this._fields()).every(this.isValid);
    }
    validationErrors = (name) => {
      const { type, fields, validations = {} } = get(this._fields(), name.replace(/\.\d+\./g, '.fields.')) || {};
      const value = get(this.state, name);
      if(type === 'list') {
        return flattenDeep((value || []).map((_, index) => {
          return keys(fields || {}).map((field) => {
            return this.validationErrors(`${name}.${index}.${field}`);
          })
        }));
      }
      return entries(validations)
        .filter(([k, v]) => !v(value, this.state, name))
        .map(([k]) => k);
    }
    onSubmit = (event) => {
      event.preventDefault();
      if(this.isUnsubmittable()) return;
      const { onSubmit } = this.props;
      const values = pick(this.state, ['id', ...keys(this._fields())]);
      this.setState({ isSubmitting: true });
      onSubmit(values);
    }
    onChangeText = (name, { target: { value } }) => {
      this.setFieldValue(name, value);
    }
    onChangeNumber = (name, { target: { value: _value } }) => {
      const value = _value !== '' ? parseFloat(_value, 10) : null;
      this.setFieldValue(name, value);
    }
    onCheck = (name, { target: { checked: value } }) => {
      this.setFieldValue(name, value);
    }
    onChangeSelect = (name, { value }) => {
      this.setFieldValue(name, value);
    }
    onSelectDatetime = (name, value) => {
      this.setFieldValue(name, value);
    }
    setFieldValue = (name, value) => {
      this.setState(set({ ...this.state }, name, value));
    }
    renderField(fieldName, { ja, type, options, fields, readOnly = _ => false, horizontal = false, readOnlyNote = '' }) {
      const validationErrors = this.validationErrors(fieldName);
      const value = get(this.state, fieldName);
      return (
        <tr key={fieldName}>
          <th>{ja}</th> 
          <td>
          {
            ({
              string: () => (
                <Input name={fieldName} value={value} onChange={this.onChangeText.bind(this, fieldName)} className={this.validationCss(fieldName)} readOnly={readOnly(value, this.state, this.props)} />
              ),
              integer: () => (
                <Input name={fieldName} type="number" step="1" value={value} onChange={this.onChangeNumber.bind(this, fieldName)} className={classnames('text-right', this.validationCss(fieldName))} readOnly={readOnly(value, this.state, this.props)} />
              ),
              boolean: () => (
                <ul class="form__list">
                  <li class="form__list__item">
                    <Label check>
                      <Input name={fieldName} type="checkbox" checked={value} onChange={this.onCheck.bind(this, fieldName)} className={this.validationCss(fieldName)} disabled={readOnly(value, this.state, this.props)} />
                      する
                    </Label>
                  </li>
                </ul>
              ),
              select: () => (
                  <Select
                    data-test={fieldName}
                    value={options.find(_ => _.value === value)}
                    onChange={this.onChangeSelect.bind(this, fieldName)}
                    className={classnames('form-select', this.validationCss(fieldName))}
                    options={options}
                    isDisabled={readOnly(value, this.state, this.props)}
                  />
              ),
              datetime: () => (
                    <DatePicker
                      data-test={fieldName}
                      showTimeSelect
                      dateFormat="yyyy/MM/dd HH:mm"
                      timeFormat="HH:mm"
                      timeIntervals={10}
                      selected={value}
                      onChange={this.onSelectDatetime.bind(this, fieldName)}
                      className={classnames('form-control', this.validationCss(fieldName))}
                      readOnly={readOnly(value, this.state, this.props)}
                    />
              ),
              date: () => (
                    <DatePicker
                      data-test={fieldName}
                      dateFormat="yyyy/MM/dd"
                      timeIntervals={10}
                      selected={value}
                      onChange={this.onSelectDatetime.bind(this, fieldName)}
                      className={classnames('form-control', this.validationCss(fieldName))}
                      readOnly={readOnly(value, this.state, this.props)}
                    />
              ),
              time: () => (
                    <DatePicker
                      data-test={fieldName}
                      showTimeSelect
                      showTimeSelectOnly
                      dateFormat="HH:mm"
                      timeFormat="HH:mm"
                      timeIntervals={5}
                      selected={value}
                      onChange={this.onSelectDatetime.bind(this, fieldName)}
                      className={classnames('form-control', this.validationCss(fieldName))}
                      readOnly={readOnly(value, this.state, this.props)}
                    />
              ),
              text: () => (
                <Input type="textarea" value={value} onChange={this.onChangeText.bind(this, fieldName)} className={this.validationCss(fieldName)} readOnly={readOnly(value, this.state, this.props)} />
              ),
            })[type]()
          }
          {
            type !== 'list' && validationErrors.length > 0 && (
              <div className="error">
                {
                  validationErrors.map(k => (
                    <div key={k}>{validationText(WrappedComponent.documentName, fieldName.replace(/\.\d+\./g, '.fields.'), k)}</div>
                  ))
                }
              </div>
            )
          }
          </td>
        </tr>
      );
    }
  };
};

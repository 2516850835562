import React, { useState, } from 'react';
import { Button, Input } from 'reactstrap';
import { isUndefined, uniq, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { format as formatDate, } from 'date-fns';
import { useToggle } from 'react-use';
import Select from 'react-select';

import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { confirm } from '../ConfirmModal';
import { benefitTargetTypes } from '../../shared/config';

const db = firebase.firestore();
const functions = firebase.app().functions('asia-northeast1');
const companiesRef = db.collection('companies');

export default function CompanyEntryBenefits(props){
  const { companyId, entryId } = props;

  const entry = useDocumentSubscription(
    companiesRef
      .doc(companyId)
      .collection('entries')
      .doc(entryId),
     [companyId, entryId]
     );
  const [referrer] = useCollectionSubscription(
    entry && companiesRef
      .doc(companyId)
      .collection('people')
      .where('lineProfile.userId', '==', entry.referrerId),
    [companyId, entry]
    );

  const [isSending, toggleSending] = useToggle(false);
  const [enableSendToReferrer,toggleEnableSendReferrer] = useToggle(true);
  const [enableSendToApplicant,toggleEnableSendApplicant] = useToggle(true);
  const [typeToReferrer, setTypeToReferrer] = useState(null);
  const [typeToApplicant, setTypeToApplicant] = useState(null);
  const [memo, setMemo] = useState('');

  // 未配送の特典から種類を抽出する
  const unsentBenefitsQuery = companiesRef
    .doc(companyId)
    .collection('benefits')
    .where('sentAt', '==', null)
    .where('sentTo', '==', null);

  const unsentBenefits = useCollectionSubscription(unsentBenefitsQuery);

  const benefitTypes = uniq(unsentBenefits.map(_ => ( _.type)).filter(_=> _ !== undefined))

  const benefitTypesOptions = benefitTypes.map(_ => ({ label: _, value: _ }));

  const sentBenefits = useCollectionSubscription(
    companiesRef
    .doc(companyId)
    .collection('benefits')
    .where('entryId','==',entryId),
     [companyId]
  );

  let hasValid = true;
  // 紹介者または応募者どちらかが有効でなければ、送信できない
  if (!(enableSendToReferrer || enableSendToApplicant)){
    hasValid = false;
  }

  // 紹介者が有効である場合、紹介者の特典種類が選択されている必要がある。
  if (enableSendToReferrer && typeToReferrer === null) {
    hasValid = false;
  }
  // 応募者が有効である場合、応募者の特典種類が選択されている必要がある。
  if (enableSendToApplicant && typeToApplicant === null) {
    hasValid = false;
  }


  const onClickSendBenefit = async () => {

    if(!(await confirm('特典を送信確認', (
      <div className="benefits">
        { enableSendToReferrer && <div className="form-line">
          {referrer && referrer.name}（紹介者）に {typeToReferrer} を送る。
        </div>
        }
        { enableSendToApplicant && <div className="form-line">
          {entry.lastName}（応募者）に {typeToApplicant} を送る。
        </div>
        }
        { 
        memo !== '' && <div className="form-line">
          <div style={{ whiteSpace: 'pre-line' }}>
          {memo}
          </div>
        </div>
        }
        <p>この内容で送信します。よろしいですか？</p>
      </div>
    )))) return;
    toggleSending(true);

    if (enableSendToReferrer){
      try {
        await db.runTransaction(async (t) => {
          console.log('Transaction start!');
          const snapshotToReferrer = await companiesRef
            .doc(companyId)
            .collection('benefits')
            .where('sentAt', '==', null)
            .where('sentTo', '==', null)
            .where('type', '==', typeToReferrer)
            .limit(1)
            .get();

          if (snapshotToReferrer.empty) {
            toast.error(referrer.name　+'（紹介者）への特典送信に失敗しました');
            return;
          }
          const docRefToReferrer = snapshotToReferrer.docs[0].ref;
          await t.get(docRefToReferrer);
          await new Promise(resolve => setTimeout(resolve, 3000));
          await t.update(docRefToReferrer, {
            sentTo: entry.referrerId,
            sentToType: 'referrer',
            memo,
            entryId: entryId
          });
          toast.success(referrer.name　+'（紹介者）への特典送信に成功しました');
        });
        console.log('Transaction success!');
      } catch (e) {
        console.log('Transaction failure:', e);
      }
    }
    if (enableSendToApplicant){
      try {
        await db.runTransaction(async (t) => {
          const snapshotToApplicant = await companiesRef
            .doc(companyId)
            .collection('benefits')
            .where('sentAt', '==', null)
            .where('sentTo', '==', null)
            .where('type', '==', typeToApplicant)
            .limit(1)
            .get();

          if (snapshotToApplicant.empty) {
            toast.error(entry.lastName+'（応募者）への特典送信に失敗しました');
            return;
          }
          const docRefToApplicant = snapshotToApplicant.docs[0].ref;
          await t.get(docRefToApplicant);
          await new Promise(resolve => setTimeout(resolve, 3000));
          await t.update(docRefToApplicant, {
            sentTo: entry.lineProfile.userId, 
            sentToType: 'applicant', 
            memo,
            entryId: entryId
          });
          toast.success(entry.lastName+'（応募者）への特典送信に成功しました');
        });
        console.log('Transaction success!');
      } catch (e) {
        console.log('Transaction failure:', e);
      }
    }


    toggleSending(false);
  };

  if (isUndefined(entry)) return <></>;

  return (
    <section className="main__section">
        <h2 className="heading2">特典送付一覧</h2>

        <div className="table">
          <table>
            <thead>
            <tr>
                <th>特典種類</th>
                <th>送信日時</th>
                <th>送信先</th>
                <th>送信先タイプ</th>
                <th>メモ</th>
            </tr>
            </thead>
            <tbody>
                {
                  sentBenefits.map((benefit) => {
                    const { id, type, sentAt, sentToType, memo } = benefit;
                    return (
                      <tr key={id}>
                        <td>
                          {type}
                        </td>
                        <td>
                          {sentAt && formatDate(sentAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                        </td>
                        <td>
                          {
                            sentToType == 'referrer' ? (referrer && referrer.name) : (entry && entry.lastName)
                          }
                        </td>
                        <td>
                          {benefitTargetTypes[sentToType]}
                        </td>
                        <td>
                          <div style={{whiteSpace: 'pre-line'}}>
                          {memo}
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
            </tbody>
          </table>
        </div>

        <h2 className="heading2">特典を送る</h2>
        <div className="benefit">
        {
          (benefitTypesOptions.length === 0) ? 
          <div className="form-line">特典が不足しているため、送ることができません。</div>
          : <>
          <div className="form-line">
            <Input type="checkbox" checked={enableSendToReferrer} onChange={_ => toggleEnableSendReferrer()} />
            {referrer && referrer.name}（紹介者）に
            <div className="select-box">
              <Select
              options={benefitTypesOptions}
              value={benefitTypesOptions.find(_ => _.value === typeToReferrer)}
              placeholder="特典種類"
              onChange={_ => setTypeToReferrer(_.value)}
              />
            </div>
            を送る
          </div>
          <div className="form-line" >
            <Input type="checkbox" checked={enableSendToApplicant} onChange={_ => toggleEnableSendApplicant()} />
            {entry.lastName}（被紹介者）に
              <div className="select-box">
              <Select
              options={benefitTypesOptions}
              value={benefitTypesOptions.find(_ => _.value === typeToApplicant)}
              placeholder="特典種類"
              onChange={_ => setTypeToApplicant(_.value)}
              />
              </div>
              を送る
          </div>
        <Input type="textarea" rows={5} value={memo} onChange={_ => setMemo(_.target.value)}　placeholder="特典メモ" />
        <div className="form__buttons">
          <Button onClick={onClickSendBenefit} className={classnames('button -primary', { '-inactive':!hasValid || isSending})} disabled={!hasValid || isSending}>
              <span className="material-icons icn">{(isSending)? 'autorenew':'send'}</span>
              <span className="txt">特典を送信</span>
          </Button>
        </div>
        </>
      }
      </div>
    </section>
  );
};

import firebase from 'firebase/compat';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/app-check';

import env from './env';

export const config =  {
  apiKey: env('FIREBASE_API_KEY'),
  authDomain: env('FIREBASE_AUTH_DOMAIN'),
  databaseURL: env('FIREBASE_DATABASE_URL'),
  projectId: env('FIREBASE_PROJECT_ID'),
  appId: env('FIREBASE_APP_ID'),
  storageBucket: env('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: env('FIREBASE_MESSAGING_SENDER_ID'),
};

firebase.initializeApp(config);

export let functions;

if(env('CLOUD_FUNCTIONS_EMULATOR') === 'true') {
  functions = firebase.app().functions('asia-northeast1');
  functions.useEmulator('localhost', 5000);
  firebase.firestore().useEmulator('localhost', 8080);
  firebase.auth().useEmulator('http://localhost:9099');
} else {
  functions = firebase.app().functions('asia-northeast1');
  firebase.appCheck().activate(env('FIREBASE_SITE_KEY'), true);
}

export default firebase;

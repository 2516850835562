import React, { Component } from 'react';
import { Button } from 'reactstrap';
import { omit } from 'lodash';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import firebase from '../../firebase';
import AdminPage from '../hocs/AdminPage';
import CompanyFormModal from '../modals/CompanyFormModal';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default AdminPage(class AdminCompanies extends Component {
  constructor() {
    super();
    this.state = {};
  }
  componentDidMount(){
    this.listenCompanies();
  }
  listenCompanies() {
    companiesRef
      .onSnapshot(({ docs }) => {
        this.setState({ companies: docs.map(_ => ({ id: _.id, ..._.data() })) });
      });
  }
  onClickAdd = () => {
    this.setState({ targetValues: undefined });
    this.openFormModal();
  }
  onClickEdit = (company) => {
    this.setState({ targetValues: company });
    this.openFormModal();
  }
  onSubmitForm = async (values) => {
    const isNew = values.id == null;
    try {
      await companiesRef.doc(...(isNew ? [] : [values.id]))[isNew ? 'set' : 'update']({ ...omit(values, 'id'), ...(isNew && { createdAt: new Date() }) });
      toast.success(`${isNew ? '追加' : '更新'}しました`);
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    } finally {
      this.closeFormModal()
    }
  }
  delete = async (id) => {
    if (!window.confirm('本当に削除しますか？')) return;
    await companiesRef.doc(id).delete();
    toast.success('削除しました');
  }
  openFormModal = () => this.setState({ shouldShowFormModal: true })
  closeFormModal = () => this.setState({ shouldShowFormModal: false })
  render () {
    const { companies = [], shouldShowFormModal = false, targetValues } = this.state;
    return (
      <div className="contents__main">
      <main className="main">
        <div className="pageTitle">
          <h1 className="heading1">会社一覧</h1>
        </div>
        <section className="main__section">
          
          <div className="buttons -line">
            <Button className="button -primary" onClick={this.onClickAdd}>
              <span className="material-icons icn">add</span>
              <span className="txt">追加</span>
            </Button>
          </div>
          <div className="table">
            <table>
              <thead>
                <tr>
                  <th>会社名</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
              {
                    companies.map((company) => {
                      const { id, name } = company;
                      return (
                      <tr key={id}>
                        <td>
                        <Link to={`/admin/companies/${id}`}>
                              {name}
                            </Link>
                        </td>
                        <td>
                          <div className="edit">
                          <Button onClick={this.onClickEdit.bind(this, company)}>
                            <span class="material-icons icn">edit</span>
                          </Button>
                          <Button onClick={this.delete.bind(this, id)}>
                            <span class="material-icons icn">delete</span>
                          </Button>
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </section>
      </main>
      <CompanyFormModal values={targetValues} isOpen={shouldShowFormModal} onClickClose={this.closeFormModal} onSubmit={this.onSubmitForm} />
      </div>

    );
  }
});

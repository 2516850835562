import React, { Fragment, useState, } from 'react';
import { Label as ReactstrapLabel, Input } from 'reactstrap';
import { get, keys, uniq} from 'lodash';
import Select from 'react-select';
import classnames from 'classnames';
import DatePicker from 'react-datepicker';
import RichTextEditor from './RichTextEditor';

import texts from '../shared/texts';
import DateSelector from './DateSelector';

const validationText = (documentName, fieldName, key) => {
  return (
    get(texts.validations[documentName], `${fieldName}.${key}`)
    || get(texts.validations.general, key)
  );
}

const findSelectedOptions = (isMulti, value, options) => {
  if(!isMulti) {
    return options.find(_ => _.value === value);
  }
  return Array.isArray(value) ? value : options.filter(_ => (keys(value || [])).includes(_.value));
}

export default function Field (props) {
  const { documentName, type, inputType = 'text', native, name: fieldName, label, value, setValue, options = [], validationErrors = [], readOnly = _ => false, shouldHide, placeholder, selector = false, yearRange = [], labelProps, rows, hint, validations = {}, readOnlyNote = '', isMulti = false, filter = _ => _, category_placeholder = '' } = props;
  const [hasStarted, setHasStarted] = useState(false);
  const [category, setCategory] = useState(null);

  const setValueAndStart = (value) => {
    setValue(filter(value));
    setHasStarted(true);
  };
  const isValid = validationErrors.length === 0;
  const validationCss = hasStarted ? classnames({ 'is-valid': isValid, 'is-invalid': isValid === false }) : '';
  if(shouldHide) return null;
  const Label = (props) => {
    return (
      <th {...props} {...labelProps} className={validations.required != null && 'required'} >
        {props.children}
      </th>
    )
  };
  return (
    <tr key={fieldName}>
      {label && <Label>{label}</Label>}  
      <td>
      {
        ({
          string: () => (
            <Fragment>
              <Input type={inputType} name={fieldName} value={value || ''} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} />
              { !!readOnlyNote && readOnly(value, props) && <span style={{ fontSize: '80%' }}>{readOnlyNote}</span> }
            </Fragment>
          ),
          password: () => (
            <Fragment>
              <Input type="password" name={fieldName} value={value || ''} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} />
            </Fragment>
          ),
          integer: () => (
            <Fragment>
              <Input name={fieldName} type="number" step="1" value={value != null ? value : ''} onChange={_ => setValueAndStart(parseInt(_.target.value))} className={classnames('text-right', validationCss)} readOnly={readOnly(value, props)} placeholder={placeholder} />
            </Fragment>
          ),
          boolean: () => (
            <Fragment>
              <ul class="form__list">
                <li class="form__list__item">
                  <label>
                  <Input name={fieldName} type="checkbox" checked={value} onChange={_ => setValueAndStart(_.target.checked)} className={validationCss} disabled={readOnly(value, props)} />
                  </label>
                </li>
              </ul>
            </Fragment>
          ),
          radio: () => (
            <Fragment>
                <ul className="form__list">
                  {
                    options.map(({ label, value: _value }) => (
                      <li check key={_value} className="form__list__item">
                        <ReactstrapLabel check>
                          <div>
                            <Input name={fieldName} type="radio" checked={_value === value} onChange={_ => setValueAndStart(_value)} className={validationCss} disabled={readOnly(value, props)} />
                          </div>
                          <div>
                            {label}
                          </div>
                        </ReactstrapLabel>
                      </li>
                    ))
                  }
                </ul>
            </Fragment>
          ),
          select: () => (
            <Fragment>
            {
              native ? (
                <label class="select material-icons" data-icon="&#58821;">
                  <select className={[classnames('form-control', validationCss),'select', 'material-icons'].join(' ')} data-icon="&#58821;" onChange={_ => setValueAndStart(_.target.value)} value={value}>
                    <option></option>
                    {
                      options.map(({ label, value }) => (
                        <option key={value} value={value} >{label}</option>
                      ))
                    }
                  </select>
                </label>
                ) : (
                  <Select
                    data-test={fieldName}
                    value={findSelectedOptions(isMulti, value, options)}
                    onChange={_ => setValueAndStart(isMulti ? _ : _.value)}
                    className={classnames('form-ritch-select', validationCss)}
                    options={options}
                    isMulti={isMulti}
                    isDisabled={readOnly(value, props)}
                    placeholder={placeholder}
                  />
                )
              }
            </Fragment>
          ),
          categorized_select: () => {
            const all_label = '全て';
            const category_names = options.filter(_=> _.category_label !== undefined).map( _ => _.category_label);
            const categories = uniq(category_names);
            categories.unshift(all_label);
            const categories_option = categories.map(v => ({label: v , value: v }));

            const filterd_options = ([all_label,null].includes(category)) ? options : options.filter( _ => _.category_label === category);
            
            // value が filterd_options 圏外であれば、options 先頭を value にセット
            let selected_value = value;
            if (![undefined,null].includes(selected_value) && ![undefined,null,all_label].includes(category)) {
              const selected_option = options.find(_ => _.value === selected_value);
              if ([undefined,null].includes(selected_option) || category !== selected_option.category_label){
                selected_value = filterd_options[0].value;
              }
            }

            return <Fragment>
              {
              (categories.length > 1) ? 
                <div style={{marginBottom: '1em'}}>
                  <Select
                    data-test={'c-'+fieldName}
                    onChange={_ => setCategory(_.value)}
                    className={classnames('form-ritch-select', validationCss)}
                    options={categories_option}
                    isDisabled={readOnly(value, props)}
                    placeholder={category_placeholder}
                  />
                </div>
               : <div></div>
              }
              <div>
                <Select
                  data-test={fieldName}
                  value={findSelectedOptions(false, selected_value, filterd_options)}
                  onChange={_ => setValueAndStart(_.value)}
                  className={classnames('form-ritch-select', validationCss)}
                  options={filterd_options}
                  isDisabled={readOnly(selected_value, props)}
                  placeholder={placeholder}
                />
              </div>
            </Fragment>
          },
          datetime: () => (
            <Fragment>
              <DatePicker
                data-test={fieldName}
                showTimeSelect
                dateFormat="yyyy/MM/dd HH:mm"
                timeFormat="HH:mm"
                timeIntervals={10}
                selected={value}
                onChange={setValueAndStart}
                className={classnames('form-control', validationCss)}
                readOnly={readOnly(value, props)}
                placeholder={placeholder}
              />
            </Fragment>
          ),
          date: () => (
            <Fragment>
                {
                  selector ? (
                    <DateSelector
                      data-test={fieldName}
                      value={value}
                      onChange={setValueAndStart}
                      className={classnames(validationCss)}
                      readOnly={readOnly(value, props)}
                      yearRange={yearRange}
                    />
                  ) : (
                    <DatePicker
                      data-test={fieldName}
                      dateFormat="yyyy/MM/dd"
                      timeIntervals={10}
                      selected={value}
                      onChange={setValueAndStart}
                      className={classnames('form-control', validationCss)}
                      readOnly={readOnly(value, props)}
                      placeholder={placeholder}
                    />
                  )
                }
            </Fragment>
          ),
          time: () => (
            <Fragment>
              <DatePicker
                data-test={fieldName}
                showTimeSelect
                showTimeSelectOnly
                dateFormat="HH:mm"
                timeFormat="HH:mm"
                timeIntervals={5}
                selected={value}
                onChange={setValueAndStart}
                className={classnames('form-control', validationCss)}
                readOnly={readOnly(value, props)}
                placeholder={placeholder}
              />
            </Fragment>
          ),
          text: () => (
            <Fragment>
                <Input type="textarea" value={value} onChange={_ => setValueAndStart(_.target.value)} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} rows={rows} />
            </Fragment>
          ),
          richText: () => (
            <Fragment>
              <RichTextEditor value={value} onChange={setValueAndStart} className={validationCss} readOnly={readOnly(value, props)} placeholder={placeholder} />
            </Fragment>
          ),
        })[type]()
      }
      {hint && <small>{hint}</small>}
      {
        hasStarted && type !== 'list' && validationErrors.length > 0 && (
          <div className="error">
            {
              validationErrors.map(k => (
                <div key={k}>{validationText(documentName, fieldName.replace(/\.\d+\./g, '.fields.'), k)}</div>
              ))
            }
          </div>
        )
      }
      </td>
    </tr>
  );
};

import React from 'react';
import { Route, Switch, } from 'react-router';

import IntegrateLINE from './components/pages/IntegrateLINE';
import Entry from './components/pages/Entry';
import AdminRoot from './components/pages/AdminRoot';
import AdminUsers from './components/pages/AdminUsers';
import AdminCompanies from './components/pages/AdminCompanies';
import AdminCompany from './components/pages/AdminCompany';
import CompanyRoot from './components/pages/CompanyRoot';
import CompanySettings from './components/pages/CompanySettings';
import CompanyUsers from './components/pages/CompanyUsers';
import CompanyShops from './components/pages/CompanyShops';
import CompanyPeoples from './components/pages/CompanyPeoples';
import CompanyPeople from './components/pages/CompanyPeople';
import CompanyBenefits from './components/pages/CompanyBenefits';
import CompanyEntries from './components/pages/CompanyEntries';
import CompanyEntry from './components/pages/CompanyEntry';
import CompanyMessageTemplates from './components/pages/CompanyMessageTemplates';
import CompanyNewPassword from './components/pages/CompanyNewPassword';

const routeObjs = [
  {
    exact: true,
    path: '/c/:companyId/integrateLINE',
    component: IntegrateLINE
  },
  {
    exact: true,
    path: '/c/:companyId/entry',
    component: Entry
  },
  {
    exact: true,
    path: '/admin',
    component: AdminRoot,
  },
  {
    exact: true,
    path: '/admin/companies',
    component: AdminCompanies,
  },
  {
    exact: true,
    path: '/admin/users',
    component: AdminUsers,
  },
  {
    exact: true,
    path: '/admin/companies/:companyId',
    component: AdminCompany,
  },
  {
    exact: true,
    path: '/companies/:companyId',
    component: CompanyRoot,
  },
  {
    exact: true,
    path: '/companies/:companyId/users',
    component: CompanyUsers,
  },
{
    exact: true,
    path: '/companies/:companyId/shops',
    component: CompanyShops,
  },
  {
    exact: true,
    path: '/companies/:companyId/peoples',
    component: CompanyPeoples,
  },
  {
    exact: true,
    path: '/companies/:companyId/peoples/:peopleId',
    component: CompanyPeople,
  },
  {
    exact: true,
    path: '/companies/:companyId/settings',
    component: CompanySettings,
  },
  {
    exact: true,
    path: '/companies/:companyId/benefits',
    component: CompanyBenefits,
  },
  {
    exact: true,
    path: '/companies/:companyId/entries',
    component: CompanyEntries,
  },
  {
    exact: true,
    path: '/companies/:companyId/entries/:entryId',
    component: CompanyEntry,
  },
  {
    exact: true,
    path: '/companies/:companyId/messageTemplates',
    component: CompanyMessageTemplates,
  },
  {
    exact: true,
    path: '/companies/:companyId/passwords/new',
    component: CompanyNewPassword,
  },
]

export default function routes (extraProps) {
  return (
    <Switch>
      {
        routeObjs.map((route , i) => {
          const { exact, path, render, props } = route;
          return (
            <Route exact={exact} path={path} render={render || (_ =>
              <route.component {..._} {...extraProps} {...props} />
            )} key={i} />
          );
        })
      }
    </Switch>
  );
};


import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../sass/img/mark.png';

import firebase from '../firebase';

const auth = firebase.auth();

export default function HeaderNav (props) {
  const { company, match: { params: { companyId } } } = props;
  const user = props.user || props.adminUser;

  const logOut = async () => {
    if(!window.confirm('ログアウトしますか？')) return;
    await auth.signOut();
    window.location.reload();
  };

  return (
    <header className="header">
      <div className="header__inner">
        <div className="header__logo">
          <Link to={`/companies/${companyId}`} className="header__logo__link">
            <span className="header__logo__mark">
              <img src={Logo} alt="リファ楽" />
            </span>
            <span className="header__logo__txt">リファ楽 管理画面</span>
          </Link>
        </div>
        <div className="header__nav">
          <h2 className="header__nav__ttl">
            {company.name}
          </h2>
          {
            user && (
          <div className="header__nav__inner">
            <div className="header__user">
              <p className="header__user__name">{ user.displayName}</p>
              <div className="header__user__dropdown">
                <ul className="header__user__dropdown__list">
                  <li className="header__user__dropdown__item">
                    <button className="header__user__dropdown__link" onClick={logOut}>
                      <span className="material-icons icn">logout</span>
                      <span className="txt">ログアウト</span>
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          )
          }

        </div>
      </div>
    </header>
  );
};

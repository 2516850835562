import React, { useState } from 'react';
import { get, pick, keyBy, isObject } from 'lodash';
import { toast } from 'react-toastify';
import { isEmail } from 'validator';

import firebase, { functions, } from '../../firebase';
import { userRoles } from '../../shared/config';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import CompanyPage from '../hocs/CompanyPage';
import ImportButton from '../ImportButton';
import ExportButton from '../ExportButton';
import DeleteButton from '../DeleteButton';
import CompanyImportFormModal from '../modals/CompanyImportFormModal';
import { fields } from '../../shared/models/companyUser';

const db = firebase.firestore();
const createCompanyUser = functions.httpsCallable('createCompanyUser');
const inviteCompanyUser = functions.httpsCallable('inviteCompanyUser');
const companiesRef = db.collection('companies');
const { keys } = Object;

export default CompanyPage(function CompanyUsers(props) {
  const { match: { params: { companyId } } } = props;
  const shops = useCollectionSubscription(companiesRef.doc(companyId).collection('shops').orderBy('index', 'asc'), [companyId]);
  const users = useCollectionSubscription(companiesRef.doc(companyId).collection('users').orderBy('createdAt', 'desc'), [companyId]);
  const shopsById = keyBy(shops, 'id');
  const usersByEmail = keyBy(users, 'email');
  const [shouldShowFormModal, setshouldShowFormModal] = useState(false);
  const [targetValues, setTargetValues] = useState(undefined);
  const [shouldShowloading, setShowloading] = useState(false);
  fields.shopIds.options = shops.map((_) => ({ value: _.id, label: _.name }));
  const processRow = async (batch, row, index) => {
    const rowNumber = index + 1;
    const { displayName, email, shop } = row;
    const role = row.role || 'staff';
    if(!keys(userRoles).includes(role)) {
      throw new Error(rowNumber ? `${rowNumber}行目 role「${role} 」は不正です` :  `role「${role} 」は不正です`);
    }
    if(!displayName || !email) {
      throw new Error(`${rowNumber}行目 displayName, emailは必須です`);
    }
    if(!isEmail(email)) {
      throw new Error(`${rowNumber}行目 email「${email}」は不正です`);
    }
    const shopIds = isObject(shop) ? shop : shop.split(',').filter(_ => _).reduce((x, y) => ({ ...x, [y]: true }), {});
    if(keys(shopIds).some(_ => !keys(shopsById).includes(_))) {
      throw new Error(rowNumber ? `${rowNumber}行目 応募先に存在しない所属IDが含まれています` : `応募先に存在しない所属IDが含まれています`);
    }
    const existingUser = usersByEmail[email];
    const exists = existingUser != null;
    //CSVインポートでなく、入力から取り込むときに（新規のみ）同じメールアドレスは弾く
    if(exists && !rowNumber && !targetValues) {
      throw new Error(`${email} はすでに使用されているメールアドレスです`);
    }
    if(!exists) {
      const { data: { uid, error } } = await createCompanyUser({ companyId, displayName, email, role, shopIds, });
      if(get(error, 'errorInfo.code') === 'auth/email-already-exists') {
        throw new Error(`${rowNumber}行目 ${email} はすでに使用されているメールアドレスです`);
      }
      await inviteCompanyUser({ companyId, uid, email });
    } else {
      const ref = companiesRef.doc(companyId).collection('users').doc(existingUser.uid);
      ref.update({ uid: existingUser.uid, displayName, email, role, shopIds, });
    }
  };
  const openFormModal = () => setshouldShowFormModal(true);
  const closeFormModal = () => setshouldShowFormModal(false);
  const onSubmitForm = async (values) => {
    try {
      values.shop = Array.isArray(values.shopIds) ? values.shopIds.reduce((x, { value }) => ({ ...x, [value]: true }), {}) : values.shopIds || '';
      const { email } = values;
      const existingUser = usersByEmail[email];
      setShowloading(true);
      await processRow(null, values);
      toast.success(`${existingUser != null ? '編集' : '追加'}しました`);
    } catch(e) {
      console.error(e);
      toast.error(e.message);
    }
    setShowloading(false);
    closeFormModal();
  }
  const onClickAdd = (value) => {
    setTargetValues(undefined);
    openFormModal();
  }
  const onClickEdit = (value) => {
    setTargetValues(value);
    openFormModal();
  }

  return (
    <div className="contents__main">
      <main className="main">
        <div className="pageTitle">
          <h1 className="heading1">ユーザー一覧</h1>
        </div>
        
        <div className="info">
          <p className="info__txt">
            応募先一覧に存在しないIDを所属に設定したユーザーはインポートできません。<br />
            先に応募先一覧を設定してください。
          </p>
        </div>
        
        <section className="main__section">
          
          <div className="buttons -line">
            <button className="button -primary" onClick={onClickAdd}>
              <span className="material-icons icn">add</span>
              <span className="txt">新規追加</span>
            </button>
            <ImportButton processRow={processRow} acceptedFields={['displayName', 'email', 'role', 'shop']} />
            <ExportButton fileName="ユーザー.csv" rows={users.map(_ => ({ ...pick(_, ['displayName', 'email', 'role']), shop: keys(_.shopIds || {}).join(',') }))} />
 
          </div>
          
          <div className="table">

            <table>
              <thead>
                <tr>
                  <th>ユーザー名</th>
                  <th>メールアドレス</th>
                  <th>権限</th>
                  <th>所属</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  users.map((user) => {
                    const { id, displayName, email, role, shopIds = {} } = user;
                    return (
                      <tr key={id}>
                        <td>
                          {displayName}
                        </td>
                        <td>
                          {email}
                        </td>
                        <td>
                          {userRoles[role]}
                        </td>
                        <td>
                          {keys(shopIds).map(_ => shopsById[_] || {}).map(_ => _.name).join(', ')}
                        </td>
                        <td>
                          <div class="edit">
                            <button onClick={onClickEdit.bind(this, user)}>
                              <span class="material-icons icn">edit_note</span>
                            </button>
                            <DeleteButton itemRef={companiesRef.doc(companyId).collection('users').doc(id)} icon='person_off' />
                          </div>
                        </td>
                      </tr>
                    );
                  })
                }
              </tbody>
            </table>
          </div>
        </section>
      </main>
      <CompanyImportFormModal values={targetValues} isOpen={shouldShowFormModal} onClickClose={closeFormModal} onSubmit={onSubmitForm} fields={fields} isLoading={shouldShowloading} title={'ユーザー'} />
    </div>
  );
});

import React, { useState, } from 'react';
import { TextDecoder } from 'text-encoding';
import { parse as parseCsv } from 'papaparse';
import { isEqual, chunk } from 'lodash';
import classnames from 'classnames';
import { Button, Input, } from 'reactstrap';
import { toast } from 'react-toastify';

import firebase from '../firebase';
import { readFile } from '../util';

const db = firebase.firestore();

export default function ImportButton ({ onComplete = _ => _, processRow, acceptedFields, ...extraProps }) {
  const [isImporting, setIsImporting] = useState(false);

  const onSelectFiles = async ({ target, target: { files: [file] } }) => {
    if(!file) return;
    setIsImporting(true);
    const decoder = new TextDecoder('Shift_JIS');
    const fileContent = decoder.decode(await readFile(file, 'readAsArrayBuffer'));
    const { data: rows, meta: { fields }, } = parseCsv(fileContent, { header: true, skipEmptyLines: true });
    if(acceptedFields != null && !isEqual(fields, acceptedFields)) {
      toast.error('csvの形式が正しくありません');
      setIsImporting(false);
      target.value = '';
      return;
    }

    await chunk(rows, 500).reduce(async (x, chunkedRows) => {
      await x;
      const batch = db.batch();
      await chunkedRows.reduce(async (x, row) => {
        await x;
        try {
          await processRow(batch, row, rows.indexOf(row) + 10000);
        } catch(e) {
          console.error(e);
          toast.error(e.message);
        }
      }, Promise.resolve());
      
      await batch.commit();
    }, Promise.resolve());
    toast.success('インポートしました');
    await onComplete(rows);
    setIsImporting(false);
    target.value = '';
  };

  return (
    <Button className={classnames('button -ghost',{'-inactive':isImporting})} disabled={isImporting} {...extraProps}>
      <label>
        <span className="material-icons icn">get_app</span>
        <span className="txt">インポート</span>
        <Input type="file" onChange={onSelectFiles} accept=".csv" style={{display: 'none'}} />
      </label>
    </Button>
  );
};



module.exports = {
  boolean: {
    true: 'はい',
    false: 'いいえ',
  },
  validations: {
    general: {
      required: '必須項目です',
      graterThanOrEqualTo0: '0以上で指定してください。',
      hiragana: 'ひらがなで入力してください',
      samePassword: 'パスワードと一致しません',
      emailFormat: 'メールアドレスの形式が正しくありません',
      tel: '10桁または11桁の0から始まる数字を入力してください',
    },
    entry: {
      postalCode: {
        format: 'ハイフンなし数字のみの7文字で入力してください',
      },
    },
    user: {
      password: {
        policy: '8文字以上、小文字、大文字、数字、記号のうち2つ以上使用してください',
      },
    },
  },
};

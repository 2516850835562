const { isEmpty, } = require('lodash');
const dedent = require('dedent');

const initialMessageForShare = dedent`
  以下のリンククリックして、
  応募しよう！
`;

const initialMessageForShareDescription = dedent`
  ご参加ありがとうございます。
  以下のメッセージをお友達に転送してください。
`;

const initialEntryPrivacyPolicyContent = dedent`
当社は、応募者よりご提出いただいた個人情報を採用選考業務（採用に関連する人事企画、施策策定、施策の実施に向けた分析、検討、施策の実施等を含む）にのみ利用しその他の目的には一切使用することはありません。
`;

module.exports = {
  initialMessageForShare,
  initialMessageForShareDescription,
  initialEntryPrivacyPolicyContent,
  settingFields: {
    name: {
      type: 'string',
      label: '名前',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    lineAccountId: {
      type: 'string',
      label: 'LINE公式アカウントID',
    },
    channelAccessToken: {
      type: 'string',
      label: 'チャネルアクセストークン',
    },
    authenticationLiffPageTitle: {
      type: 'string',
      label: '認証LIFF 上部タイトル',
      initialValue: 'リファ楽 紹介プログラム参加',
    },
    authenticationLiffUrl: {
      type: 'string',
      label: '認証LIFF URL',
    },
    authenticationLiffShopIdEnabled: {
      type: 'boolean',
      label: '認証時に所属を表示する',
      initialValue: false,
    },
    authenticationLiffNameEnabled: {
      type: 'boolean',
      label: '認証時に名前を表示する',
      initialValue: true,
    },
    authenticationLiffEmployeeNumberEnabled: {
      type: 'boolean',
      label: '認証時に従業員番号を表示する',
      initialValue: false,
    },
    authenticationLiffBirthDayEnabled: {
      type: 'boolean',
      label: '認証時に生年月日を表示する',
      initialValue: false,
    },
    entryLiffPageTitle: {
      type: 'string',
      label: '応募LIFF 上部タイトル',
      initialValue: 'リファ楽 応募',
    },
    entryLiffUrl: {
      type: 'string',
      label: '応募LIFF URL',
    },
    webhookUrl: {
      type: 'string',
      label: 'Webhook URL',
    },
    messageForShareDescription: {
      type: 'text',
      label: 'シェア方法の説明',
      initialValue: initialMessageForShareDescription,
      placeholder: initialMessageForShareDescription,
    },
    messageForShare: {
      type: 'text',
      label: 'シェア用メッセージ',
      initialValue: initialMessageForShare,
      placeholder: initialMessageForShare,
    },
    messageAtEntryEnabled: {
      type: 'boolean',
      label: '応募完了時にメッセージを送信する',
      initialValue: false,
    },
    messageAtEntryForReferrer: {
      type: 'text',
      label: '応募完了時の紹介者へのメッセージ',
      readOnly: (v, { values }) => !values.messageAtEntryEnabled,
    },
    messageAtEntryForApplicant: {
      type: 'text',
      label: '応募完了時の被紹介者へのメッセージ',
      readOnly: (v, { values }) => !values.messageAtEntryEnabled,
    },
    entryPageContent: {
      type: 'richText',
      label: '応募ページコンテンツ',
    },
    entryPrivacyPolicyContent: {
      type: 'richText',
      label: '個人情報の取扱い',
      initialValue: initialEntryPrivacyPolicyContent,
      placeholder: initialEntryPrivacyPolicyContent,
    },
  },
  adminSettingFields: {
    entryActionWaitingDays: {
      type: 'integer',
      label: '未処理の応募についてアラートする経過日数',
      initialValue: 7,
      validations: {
        required: v => v >= 0,
      },
    },
  }
};

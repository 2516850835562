import React from 'react';
import { Button, Modal, Form, } from 'reactstrap';
import { mapValues } from 'lodash';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { settingFields as fields } from '../../shared/models/entry';
import classnames from 'classnames';

const { entries } = Object;

export default function CompanySettingsFormModal(props) {
  const { isOpen, values, onClickClose } = props;
  const statedFields = useFormState(values, fields, isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({ ...mapValues(statedFields, 'value') });
  };

  return (
    <Modal isOpen={isOpen} style={{ minWidth: '70vw' }}>
      <div className="modal__inner">
        <div className="modal__header">
          <h3 className="heading2">
          応募編集
          </h3>
        </div>
        <div className="modal__body">
          <Form onSubmit={onSubmit}>
            <table class="form__table">
              <tbody>
                {
                  entries(statedFields).map(([fieldName, fieldSetting]) => (
                    <Field
                      key={fieldName}
                      name={fieldName}
                      {...fieldSetting}
                    />
                  ))
                }
              </tbody>
            </table>
            <div class="form__buttons">
              <Button className="button -secondary" onClick={onClickClose}>
                <span class="txt">キャンセル</span>
              </Button>
              <Button className={classnames('button -primary',{'-inactive':isUnsubmittable})} onClick={onSubmit} disabled={isUnsubmittable}>
                <span class="txt">保存</span>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../sass/img/mark.png';

export default class HeaderNav extends Component {
  render() {
    return (
      <header className="header">
        <div className="header__inner">
          <div className="header__logo">
            <Link to="/admin" className="header__logo__link">
              <span className="header__logo__mark">
                <img src={Logo} alt="リファ楽" />
              </span>
              <span className="header__logo__txt">リファ楽 管理画面</span>
            </Link>
          </div>
          <div className="header__nav">
            <h2 className="header__nav__ttl">&nbsp;</h2>
            <div className="header__nav__inner">
              <div className="header__user">
                <p className="header__user__name">{this.props.displayName}</p>
              </div>
            </div>
          </div>
        </div>
      </header>
      

    );
  }
}

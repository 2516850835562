import React, { useState, useEffect } from 'react';
import { pick, } from 'lodash';
import { toast } from 'react-toastify';
import qs from 'qs';

import firebase from '../../firebase';
import CompanyHeaderNav from '../CompanyHeaderNav';
import CompanySidebarNav from '../CompanySidebarNav';
import SignInForm from '../forms/SignInForm';
import useDocumentSubscription from '../hooks/useDocumentSubscription';

import Logo from '../../sass/img/mark.png';

const auth = firebase.auth();
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const usersRef = db.collection('users');

export default function CompanyPageHOC(WrappedComponent) {
  return function CompanyPage (props) {
    const { history, location, location: { search }, match: { params: { companyId } } } = props;
    const [firebaseUser, setFirebaseUser] = useState();
    const [shouldShowLoginForm, setShouldShowLoginForm] = useState(false);
    const company = useDocumentSubscription(companiesRef.doc(companyId), [companyId, firebaseUser]);
    const user = useDocumentSubscription(firebaseUser && companiesRef.doc(companyId).collection('users').doc(firebaseUser.uid), [companyId, firebaseUser]);
    const adminUser = useDocumentSubscription(usersRef.doc(...(firebaseUser ? [firebaseUser.uid] : [])), [firebaseUser]);

    // 移行措置：本項目が追加される前に設定された企業については氏名の表示をONにする
    if (!!company && company.authenticationLiffNameEnabled === undefined) {
      company.authenticationLiffNameEnabled = true;
    }

    useEffect(() => {
      auth.onAuthStateChanged((firebaseUser) => {
        setFirebaseUser(firebaseUser);
        if (!firebaseUser) {
          auth.signOut();
          setShouldShowLoginForm(true);
        }
      });
    }, []);

    const processCompanyInvitation = async () => {
      const { companyInvitationToken } = qs.parse(search.slice(1));
      if(!companyInvitationToken) return;
      const { exists, ref } = await companiesRef.doc(companyId).collection('invitations').doc(companyInvitationToken).get();
      if(!exists) {
        toast.error('Invalid invitation token.');
        return history.replace(location.pathname);
      }
      await ref.update(pick(firebaseUser, ['uid', 'email', 'displayName']));
      toast.success('招待を承認しました');
    };

    const onSubmitSignIn = async ({ email, password }) => {
      try {
        await auth.signInWithEmailAndPassword(email, password);
      } catch(e) {
        console.log()
        const message = ({
          'auth/invalid-credential': 'メールアドレスかパスワードが間違っています',
        })[e.code] || 'ログインできませんでした';
        toast.error(message);
        console.error(e);
      }
    };

    useEffect(() => {
      if(firebaseUser) {
        processCompanyInvitation();
      }
    }, [firebaseUser]);

    return (
      <div>
        {
          firebaseUser ? (
            company && (
              <div>
                <CompanyHeaderNav {...props} {...{ company, firebaseUser, user, adminUser }} />
                {
                  (user || adminUser) ? (
                    <div className="contents">
                      <CompanySidebarNav {...props} {...{ company, firebaseUser, user, adminUser }} />
                      <WrappedComponent {...props} {...{ company, firebaseUser, user, adminUser }} />
                    </div>
                ) : (
                  <div>Not authorized. Please get invitation.</div>
                )
              }
              </div>
            )
          ) : (
            shouldShowLoginForm && (
              <div className="login">
                <header className="login__header">
                  <span className="login__header__mark">
                    <img src={Logo} alt="リファ楽" />
                  </span>
                  <span className="login__header__txt">リファ楽 ログイン</span>
                </header>
                <main>
                  <div className="login__box">
                    <SignInForm onSubmit={onSubmitSignIn} companyId={companyId} />
                  </div>
                </main>
              </div>
            )
          )
        }
      </div>
    );
  };
};

import React, { useEffect, useState, Fragment } from 'react';
import { Form, Button } from 'reactstrap';
import { omitBy, mapValues } from 'lodash';

import qs from 'qs';
import sanitizeHtml from 'sanitize-html';
import { useToggle } from 'react-use';
import { useTitle, } from 'react-use';
import classnames from 'classnames';
import axios from 'axios';
import axiosJsonp from 'axios-jsonp';

import { initialEntryPrivacyPolicyContent, settingFields as companySettingsFields } from '../../shared/models/company';
import firebase from '../../firebase';
import { functions } from '../../firebase';
import { fields } from '../../shared/models/entry';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { getLiffIdFromUrl } from '../../util';
import useFunctionsReturn from '../hooks/useFunctionsReturn';
import useLineProfile from '../hooks/useLineProfile';
import { liff } from '../../liff';
import OverlayLoading from '../OverlayLoading';


const db = firebase.firestore();
const companiesRef = db.collection('companies');
const getCompany = functions.httpsCallable('getCompany');
const getCompanyShops = functions.httpsCallable('getCompanyShops');
const getByLineUser = functions.httpsCallable('getByLineUser');
const createEntry = functions.httpsCallable('createEntry');
const countUpEntryUsers = functions.httpsCallable('countUpEntryUsers');

const onFetchZip = (field, value) => {
  if(value.match(/^[0-9]{7}$/g)) {
    axios({
      url: 'https://map.yahooapis.jp/search/zip/V1/zipCodeSearch',
      adapter: axiosJsonp,
      callbackParamName: 'callback',
      params: {
        query: value,
        output: 'json',
        appid: 'dj00aiZpPWgzVXRXMEJValo0VyZzPWNvbnN1bWVyc2VjcmV0Jng9ZjI-'
      }
    }).then(({ data }) => {
      const { Feature = null } = data;
      if(Feature) {
        const { setValues, values } = field;
        const [prefectureToCity, prefecture, city] = /(.+[県 都 府 道])(.+)/.exec(Feature[0].Property.Address);
        setValues({ ...values, postalCode: value, prefecture, city });
      }
    });
  }
  return value;
}

export default function Entry (props) {
  const { location: { search }, match: { params: { companyId } } } = props;
  const { r: referrerId } = qs.parse(search.slice(1));
  const [company, setCompany] = useState(undefined);
  const [referrers, setReferrers] = useState(undefined);
  const [shops, setShops] = useState(undefined);
  const referrer = !!referrers ? referrers[0] : undefined;
  const [sameLineUserIdPeople, setSameLineUserIdPeople] = useState(undefined);
  const [sameLineUserIdEntries, setSameLineUserIdEntries] = useState(undefined);

  useFunctionsReturn(() => getCompany({ companyId }), setCompany);
  useTitle(company && (company.entryLiffPageTitle || companySettingsFields.entryLiffPageTitle.initialValue));
  const { entryFormSettings = {} } = company || {};
  const { customItems = [] } = entryFormSettings;
  useFunctionsReturn(() => getCompanyShops({ companyId }), setShops, [companyId]);

  const lineProfile = useLineProfile(company && getLiffIdFromUrl(company.entryLiffUrl));
  const lineUserId = lineProfile ? lineProfile.userId : null;
  useFunctionsReturn(() => getByLineUser({ companyId, collectionName: 'people', lineUserId }), setSameLineUserIdPeople, [companyId, lineUserId]);
  useFunctionsReturn(() => getByLineUser({ companyId, collectionName: 'entries', lineUserId }), setSameLineUserIdEntries, [companyId, lineUserId]);
  useFunctionsReturn(() => getByLineUser({ companyId, collectionName: 'people', lineUserId: referrerId }), setReferrers, [companyId, referrerId]);
  const isAlreadyPerson = !!sameLineUserIdPeople && sameLineUserIdPeople.length > 0;
  const [isSubmitting, toggleIsSubmitting] = useToggle(false);
  const [showsFollowInstruction, toggleFollowInstruction] = useToggle(false);
  const [showsCloseButton, toggleCloseButton] = useToggle(false);

  const baseFields = omitBy(
    fields({ shops, initialShopId: (referrer || {}).shopId }),
    (v, k) => (entryFormSettings[k] || {}).enabled === false,
  );
  const fieldsByCustomItems = customItems.reduce((x, { description, type, optionsString, isRequired }) => {
    return {
      ...x,
      [description]: {
        label: description,
        type,
        validations: isRequired ? {
          required: v => v != null && v !== '',
        } : {},
        options: (optionsString || '').split(',').map(_ => ({ label: _, value: _, })),
        initialValue: null,
      },
    };
  }, {});
  const allFields = { ...baseFields, ...fieldsByCustomItems };
  const isCallZipApi = (allFields.prefecture !== void 0 && allFields.postalCode !== void 0 && allFields.city !== void 0);
  const statedFields = useFormState({}, allFields, referrer != null || company != null);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const isLoading = company === undefined
    || sameLineUserIdPeople === undefined
    || sameLineUserIdEntries === undefined
    || shops === undefined
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    toggleIsSubmitting();
    const { data: { friendExists } } = await createEntry({
      companyId, referrerId, lineProfile,
      formFields: mapValues(statedFields, (f) => {
        return { value: f.value, type: f.type };
      })
    });
    if(friendExists) {
      liff.closeWindow();
    } else {
      toggleFollowInstruction(true);
      await new Promise(_ => setTimeout(_, 2000));
      window.scroll(0, 0);
    }
  }
  const saveCount = async () => {
    if(!companyId || !referrer || !lineProfile) return;
    await countUpEntryUsers({ companyId, referrerId: referrer.id, lineProfile });
  };

  useEffect(() => {
    saveCount();
  }, [referrer != null, lineProfile]);

  return (
    <>
      <OverlayLoading isOpen={isLoading} text="読み込み中です、少々お待ちください。" />
      <div id="embed_line">
        {
          !showsFollowInstruction ? (
            <div>
              <h2>応募フォーム</h2>
              {
                (!!sameLineUserIdEntries && sameLineUserIdEntries.filter(_ => _.status !== 'rejected').length > 0 && !isAlreadyPerson) ? (
                  <>
                    <div className="alert">
                    同じLINEアカウントで既に応募済みです。友だち追加すると応募先から案内が送られます。
                    </div>
                    <div className="form-group">
                      <Button className="btn" tag="a" color="success" href={`line://ti/p/${(company || {}).lineAccountId}`}>
                        友達に追加する
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="alert">
                      <div dangerouslySetInnerHTML={{ __html: sanitizeHtml((company || {}).entryPageContent, { allowedTags: [...sanitizeHtml.defaults.allowedTags, 'h2'] }) }} />
                    </div>
                    <Form onSubmit={onSubmit}>
                      <table className="form-table">
                        <tbody>
                        {
                          Object.entries(statedFields).map(([fieldName, fieldSetting]) => {
                            //郵便番号のAPIを呼び出し住所補間
                            if(fieldName === 'postalCode' && isCallZipApi) fieldSetting.filter = onFetchZip.bind(this, statedFields.postalCode)
                            return  (
                              <Field
                                documentName="entry"
                                key={fieldName}
                                name={fieldName}
                                {...fieldSetting}
                              />
                            )
                          })
                        }
                        <tr key="entryPrivacyPolicyContent">
                          <th>
                            個人情報の取扱いについて
                          </th>
                          <td>
                            <div className="alert">
                              <div dangerouslySetInnerHTML={
                                { __html: sanitizeHtml(
                                  (company || {}).entryPrivacyPolicyContent || initialEntryPrivacyPolicyContent, 
                                  { allowedTags: [...sanitizeHtml.defaults.allowedTags, 'h2'] }
                                  )
                                }
                                } style={{maxHeight:300, overflowY:'scroll'}} />
                            </div>
                            <small>ご応募いただいた場合は当社の個人情報の取扱いに同意したとみなします。</small>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                      <Button
                        className={classnames('btn', { '-inactive': isUnsubmittable })}
                        block
                        disabled={isSubmitting || isUnsubmittable || isAlreadyPerson}
                      >
                        {
                          isAlreadyPerson ? '既に社員のため応募できません' : '応募する'
                        }
                      </Button>
                    </Form>
                  </>
                )
              }
            </div>
          ) : (
            <div>
              <h2>
                応募ありがとうございます。
              </h2>
              <div className="alert">
                この応募を完了するために、
                <br />
                以下のボタンから友達に追加してください。
              </div>
              <div className="form-group">
                <Button className="btn" tag="a" color="success" href={`line://ti/p/${(company || {}).lineAccountId}`} onClick={toggleCloseButton}>
                  友達に追加する
                </Button>
              </div>
              <div className="form-group">
              <Button size="sm" color="link" onClick={() => liff.closeWindow()} className={classnames('btn font-weight-bold', { invisible: !showsCloseButton })}>
                閉じる
              </Button>
              </div>
            </div>
          )
        }
      </div>
    </>
  );
};

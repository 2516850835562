import React from 'react';
import { omit, } from 'lodash';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import { useToggle } from 'react-use';
import ellipsis from 'text-ellipsis';

import firebase from '../../firebase';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import CompanyPage from '../hocs/CompanyPage';
import MessageTemplateFormModal from '../modals/MessageTemplateFormModal';
import EditButton from '../EditButton';
import DeleteButton from '../DeleteButton';

const db = firebase.firestore();
const companiesRef = db.collection('companies');

export default CompanyPage(function CompanyMessageTemplates(props) {
  const { match: { params: { companyId } } } = props;
  const messageTemplates = useCollectionSubscription(companiesRef.doc(companyId).collection('messageTemplates').orderBy('createdAt', 'desc'), [companyId]);
  const [showsFormModal, toggleFormModal] = useToggle();
  const onSubmitForm = async (values) => {
    try {
      await companiesRef.doc(companyId).collection('messageTemplates').add({ ...omit(values, 'id'), createdAt: new Date() });
      toast.success('追加しました');
    } catch(e) {
      toast.error('失敗しました');
      console.error(e);
    }
    toggleFormModal();
  };

  return (
    <div className="contents__main">
      <main className="main">
        <div className="pageTitle">
          <h1 className="heading1">メッセージテンプレート一覧</h1>
        </div>
        <section className="main__section">
          
          <div className="buttons -line">
            <button className="button -primary" onClick={toggleFormModal}>
              <span className="material-icons icn">add</span>
              <span className="txt">新規追加</span>
            </button>
          </div>
          
          <div className="table">
            {
              messageTemplates.length > 0 ? (
                <table className="table">
                  <thead>
                    <tr className="text-nowrap">
                      <th>名称</th>
                      <th>本文</th>
                      <th>作成日時</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      messageTemplates.map((messageTemplate) => {
                        const { id, ref, name, body, createdAt, } = messageTemplate;
                        return (
                          <tr key={id} title={name}>
                            <td>
                              {ellipsis(name, 30)}
                            </td>
                            <td title={body}>
                              {ellipsis(body, 50)}
                            </td>
                            <td>
                              {formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                            </td>
                            <td>
                              <div className="edit">
                                <EditButton itemRef={ref} FormModal={MessageTemplateFormModal} onlyIconButton={true} />
                                <DeleteButton itemRef={ref} />
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              ) : (
                <div>
                  メッセージテンプレートは未登録です
                </div>
              )
            }
          </div>
        </section>
      </main>
      <MessageTemplateFormModal isOpen={showsFormModal} onClickClose={toggleFormModal} onSubmit={onSubmitForm} />
    </div>
  );
});

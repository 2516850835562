import React, { Component } from 'react';
import { Button, Modal, Input } from 'reactstrap';
import uuid from 'uuid';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';

import firebase from '../../firebase';

const db = firebase.firestore();
const invitationsRef = db.collection('invitations');

export default class AdminInvitationModal extends Component {
  constructor() {
    super();
    this.state = {
      token: null,
    };
  }
  componentDidUpdate(prevProps) {
    if(!prevProps.isOpen && this.props.isOpen) {
      this.setState({ token: null });
    }
  }
  generateInvitationUrl = async () => {
    const token = uuid();
    const { exists, ref } = await invitationsRef.doc(token).get();
    if(exists) return this.generateInvitationUrl();
    await ref.set({ token, createdAt: new Date() });
    this.setState({ token });
  }
  invitationUrl() {
    const { origin } = window.location;
    const { token } = this.state;
    return `${origin}/admin?adminInvitationToken=${token}`;
  }
  onClickCopy = () => {
    copy(this.invitationUrl());
    toast.success('コピーしました');
  }
  render() {
    const { isOpen, onClickClose } = this.props;
    const { token } = this.state;
    return (
      <Modal isOpen={isOpen}>
        <div class="modal__inner">
          <div class="modal__header">
            <h3 class="heading2">招待</h3>
            <div class="modal__close js-close-modal">
              <span class="material-icons icn" onClick={onClickClose}>close</span>
            </div>
          </div>
          <div class="modal__body">
          {
            token ? (
              <div>
              <div class="form__buttons">
                  <Input readOnly defaultValue={this.invitationUrl()} />
                  <Button color="primary" onClick={this.onClickCopy}>
                    <span class="material-icons icn">content_copy</span>
                  </Button>
              </div>
              <div class="form__buttons">
                ※一度しか表示されません
              </div>
              </div>
            ) : (
              <div class="form__buttons">
                <button class="button -primary" onClick={this.generateInvitationUrl}>
                  <span class="txt">招待URLを取得する</span>
                </button>
              </div>
            )
          }
          </div>
        </div>
      </Modal>
    );
  }
};

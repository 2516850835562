import React, { useEffect, useState, Fragment } from 'react';
import { Button, Modal, Form, FormGroup, Input, Label } from 'reactstrap';
import { omit, isEmpty, mapValues } from 'lodash';
import { useList, useMap } from 'react-use';
import { toast } from 'react-toastify';

import firebase from '../../firebase';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import ListForm from '../ListForm';
import { fields as _entryFields } from '../../shared/models/entry';
import { customItemTypes } from '../../shared/config';
import classnames from 'classnames';

const { entries } = Object;
const db = firebase.firestore();
const companiesRef = db.collection('companies');
const customItemFields = {
  description: {
    label: '項目名',
    type: 'string',
    validations: { required:  v => !isEmpty(v), },
  },
  type: {
    label: '入力タイプ',
    type: 'select',
    options: entries(customItemTypes).map(([k, v]) => ({ value: k, label: v })),
    validations: { required:  v => !isEmpty(v), },
    initialValue: 'string',
  },
  optionsString: {
    label: '選択肢',
    type: 'text',
    validations: { required:  v => !isEmpty(v), },
    hidden: _ => !['select', 'radio', 'checkbox'].includes(_.type),
    placeholder: 'カンマ区切り',
    hint: 'カンマ区切りで入力してください。',
  },
  isRequired: {
    label: '必須',
    type: 'boolean',
    initialValue: false,
  },
  webhook: {
    label: 'webhook送信',
    type: 'boolean',
    initialValue: true,
  },
};

function CustomItemForm (props) {
  const { values, isValidAt, setIsValidAt, itemIndex, onChange } = props;
  const statedFields = useFormState(values, customItemFields, values);
  useEffect(() => {
    onChange(mapValues(statedFields, 'value'));
    const isValidAtTmp = isValidAt;
    isValidAtTmp[itemIndex] = Object.values(statedFields).every(f => f.isValid);
    setIsValidAt(isValidAtTmp);
  }, Object.values(statedFields).map(_ => _.value));

  return (
    <Fragment>
      {
        entries(statedFields).map(([fieldName, fieldSetting]) => (
            <Field
              name={fieldName}
              {...fieldSetting}
            />
        ))
      }
    </Fragment>
  );
}

export default function CompanySettingsFormModal(props) {
  const { isOpen, company, onClickClose } = props;
  const { entryFormSettings = {} } = company;
  const [values, { set }] = useMap(entryFormSettings);
  const entryFields = omit(_entryFields(), 'shopId');
  const [customItems, { set: setCustomItems, updateAt: updateCustomItemAt }] = useList(entryFormSettings.customItems || []);
  const [ isValidAt, setIsValidAt ] = useState([]);
  const isUnsubmittable = isValidAt.some(v => !v);
  const onSubmit = async (event) => {
    event.preventDefault();
    if (isUnsubmittable) {
      return;
    }
    await companiesRef.doc(company.id).update({ entryFormSettings: { ...values, customItems }, });
    toast.success('更新しました');
    onClickClose();
  };

  return (
    <Modal isOpen={isOpen}>
      <div class="modal__inner">
        <div class="modal__header">
          <h3 class="heading2">
          応募フォーム設定
          </h3>
          <div class="modal__close js-close-modal">
            <span class="material-icons icn" onClick={onClickClose}>close</span>
          </div>
        </div>
        <div class="modal__body">
          <Form onSubmit={onSubmit}>
            <div className="table">
              <table>
                <thead>
                  <tr>
                    <th>項目名</th>
                    <th>表示</th>
                    <th>webhook送信</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    entries(entryFields).map(([fieldName, { label, }]) => {
                      const { [fieldName]: { enabled = true, webhook = true } = {} } = values;
                      return (
                        <tr key={fieldName}>
                          <th>
                            {label}
                          </th>
                          <td>
                            <div>
                              <FormGroup check>
                                <Label check>
                                  <Input type="radio" checked={enabled} onChange={_ => set(fieldName, { enabled: true, webhook, })} />
                                  ON
                                </Label>
                              </FormGroup>
                              <FormGroup check>
                                <Label check>
                                  <Input type="radio" checked={!enabled} onChange={_ => set(fieldName, { enabled: false, webhook, })} />
                                  OFF
                                </Label>
                              </FormGroup>
                            </div>
                          </td>
                          <td>
                            {
                              enabled && (
                                <div>
                                  <FormGroup check>
                                    <Label check>
                                      <Input type="radio" checked={webhook} onChange={_ => set(fieldName, { enabled, webhook: true, })} />
                                      ON
                                    </Label>
                                  </FormGroup>
                                  <FormGroup check>
                                    <Label check>
                                      <Input type="radio" checked={!webhook} onChange={_ => set(fieldName, { enabled, webhook: false, })} />
                                      OFF
                                    </Label>
                                  </FormGroup>
                                </div>
                              )
                            }
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </table>
            </div>
            <hr />
            <div>
              <h4>カスタム項目</h4>
              <ListForm
                items={customItems}
                renderItem={(item, itemIndex) => {
                  return (
                    <CustomItemForm
                      values={item}
                      onChange={_ => updateCustomItemAt(itemIndex, { ...item, ..._ })}
                      {...{isValidAt, setIsValidAt, itemIndex}}
                    />
                  );
                }}
                onChange={(items, itemIndex = null) => {
                  if(itemIndex != null) {
                    setIsValidAt([...isValidAt.slice(0, itemIndex), ...isValidAt.slice(itemIndex + 1)]);
                  }
                  setCustomItems(items);
                }}
              />
            </div>
            <div class="form__buttons">
              <Button className="button -secondary" onClick={onClickClose}>
                <span class="txt">キャンセル</span>
              </Button>
              <Button className={classnames({'button':true,'-primary':true, '-inactive': isUnsubmittable })} type="submit" onClick={onSubmit}>
                <span class="txt">保存</span>
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </Modal>
  );
};

import React, { useState, useEffect, } from 'react';
import { EventEmitter } from 'events';
import { Button, Modal} from 'reactstrap';
import { useToggle } from 'react-use';

const eventEmitter = new EventEmitter();

export default function ConfirmModal (props) {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [{ resolve }, setResolve] = useState({});
  const [isOpen, toggle] = useToggle();
  const abort = () => {
    toggle();
    resolve(false);
  };
  const confirm = () => {
    toggle();
    resolve(true);
  };
  const initFocus = element => {
    if (element) {
      // NOTE: 初期のフォーカスが当たらないためsetTimeoutにて遅延
      setTimeout(() => {
        element.focus();
      }, 0);
    }
  };
  useEffect(() => {
    eventEmitter.on('show', ({ title, body, resolve }) => {
      toggle();
      setTitle(title);
      setBody(body);
      setResolve({ resolve });
    });
  }, []);

  return (
    <Modal isOpen={isOpen}>
      <div className="modal__inner">
        {title && (
          <div className="modal__header">
            <h3 className="heading2">
              {title}
            </h3>
          </div>
        )}
        <div className="modal__body">
          {body}
          <div className="form__buttons">
            <Button className="button -secondary" onClick={abort} innerRef={initFocus}>
              <span className="txt">キャンセル</span>
            </Button>
            <Button name="ok" className="button -primary" onClick={confirm}>
              <span className="txt">OK</span>
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export const confirm = (title, body) => {
  return new Promise((resolve, reject) => {
    try {
      eventEmitter.emit('show', { title, body, resolve });
    } catch (e) {
      reject(e);
      throw e;
    }
  });
};

import React from 'react';
import classnames from 'classnames';

export default function OverlayLoading(props) {
  const { isOpen = false, text = '' } = props;
  return (
    <div className={classnames('overlay-loading d-flex justify-content-center align-items-center', { show: isOpen })} style={{ 
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: '#fafafa',
      opacity: isOpen ? 0.9 : 0,
      transition: 'opacity 0.3s linear',
      pointerEvents: isOpen ? 'all' : 'none',
      zIndex: 10000,
    }}>
      <span className="fas fa-spin fa-spinner mr-1" />
      {text}...
    </div>
  );
};

import React from 'react';
import { Button, Form, } from 'reactstrap';
import { isEmpty, mapValues } from 'lodash';

import useFormState from '../hooks/useFormState';
import Field from '../Field';
import classnames from 'classnames';
import { PASSWORD_POLICY_REG } from '../../shared/constants';

const { entries } = Object;
const fields = {
  password: {
    type: 'password',
    label: 'パスワード',
    validations: {
      required: v => !isEmpty(v),
      policy: v => (v || '').match(PASSWORD_POLICY_REG)
    },
  },
  passwordConfirmation: {
    type: 'password',
    label: 'パスワード確認',
    validations: {
      required: (v) => !isEmpty(v),
      samePassword: (v, { password }) => v === password,
    },
  },
};

export default function NewPasswordForm(props) {
  const { disabled = false } = props;
  const statedFields = useFormState({}, fields);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;
    props.onSubmit({ ...mapValues(statedFields, 'value') });
  };

  return (
    <Form onSubmit={onSubmit}>
      <table>
        <tbody>
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => (
              <Field
                key={fieldName}
                name={fieldName}
                {...fieldSetting}
                documentName="user"
              />
            ))
          }
        </tbody>
      </table>
      <div className="login__box__btn">
        <Button type="submit" className={classnames('button -primary',{'-inactive':isUnsubmittable || disabled})} disabled={isUnsubmittable || disabled}>
          <span class="txt">設定する</span>
        </Button>
      </div>
    </Form>
  );
};

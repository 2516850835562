import React, { useState, } from 'react';
import { Form, Button } from 'reactstrap';
import { format as formatDate, } from 'date-fns';
import { isEmpty } from 'lodash';
import { useToggle, useTitle, } from 'react-use';
import Select from 'react-select';
import classnames from 'classnames';

import { settingFields as companySettingsFields } from '../../shared/models/company';
import firebase from '../../firebase';
import { functions } from '../../firebase';
import { getLiffIdFromUrl } from '../../util';
import useFunctionsReturn from '../hooks/useFunctionsReturn';
import useLineProfile from '../hooks/useLineProfile';
import { fields } from '../../shared/models/introduction';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import { liff } from '../../liff';
import OverlayLoading from '../OverlayLoading';

const db = firebase.firestore();
const companiesRef = db.collection('companies');
const addCompanyPeople = functions.httpsCallable('addCompanyPeople');
const getCompany = functions.httpsCallable('getCompany');
const getCompanyShops = functions.httpsCallable('getCompanyShops');
const getByLineUser = functions.httpsCallable('getByLineUser');

const isShowField = (fieldName, company) => {
  switch (fieldName) {
    case "name":
      return !(company && company.authenticationLiffNameEnabled)
    case "shopId":
      return !(company && company.authenticationLiffShopIdEnabled)
    case "employeeNumber":
      return !(company && company.authenticationLiffEmployeeNumberEnabled)
    case "birthday":
      return !(company && company.authenticationLiffBirthDayEnabled)
    default:
      return false;
  }
}

// それぞれのフィールドが表示されている時の場合必須にするバリデーション
const isDisabledField = (company, statedFields) => {
  return ((company && company.authenticationLiffNameEnabled) && isEmpty(statedFields.name.value)) ||
    ((company && company.authenticationLiffShopIdEnabled) && isEmpty(statedFields.shopId.value)) ||
    ((company && company.authenticationLiffEmployeeNumberEnabled) && isEmpty(statedFields.employeeNumber.value)) ||
    ((company && company.authenticationLiffBirthDayEnabled) && !statedFields.birthday.value)
}

export default function IntegrateLINE (props) {
  const { match: { params: { companyId } } } = props;
  const [company, setCompany] = useState(undefined);
  const [people, setPeople] = useState(undefined);
  const [shops, setShops] = useState(undefined);
  useFunctionsReturn(() => getCompany({ companyId }), setCompany);
  useTitle(company && (company.authenticationLiffPageTitle || companySettingsFields.authenticationLiffPageTitle.initialValue));
  useFunctionsReturn(() => getCompanyShops({ companyId }), setShops, [companyId]);
  const lineProfile = useLineProfile(company && getLiffIdFromUrl(company.authenticationLiffUrl));
  const lineUserId = lineProfile ? lineProfile.userId : null;
  useFunctionsReturn(() => getByLineUser({ companyId, collectionName: 'people', lineUserId }), setPeople, [lineUserId]);
  const isAlreadyPerson = !!people && people.length > 0;
  const [isSubmitting, toggleIsSubmitting] = useToggle();

  const allFields = fields({ shops: shops || [], readOnlyFlag: isSubmitting});
  const statedFields = useFormState({}, allFields , false);
  const isUnsubmittable = isDisabledField(company, statedFields);
  const isLoading = company === undefined || people === undefined || shops === undefined;
  const isFormShown =  Object.keys(statedFields).some((fieldName) => !isShowField(fieldName, company))
  const onSubmitCreationForm = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) {
      return;
    }
    toggleIsSubmitting();
    const { name: { value:name }, shopId: { value:shopId }, employeeNumber: { value:employeeNumber }, birthday:{ value:birthday } } = statedFields
    const { data: { error } = {} } = await addCompanyPeople({
        companyId,
        name,
        lineProfile,
        shopId,
        employeeNumber,
        ...((company && company.authenticationLiffBirthDayEnabled) ? { birthday: formatDate(birthday, 'yyyy/MM/dd')  } : {})
      });
      console.log(error);
    liff.closeWindow();
  };

  return (
    <>
      <OverlayLoading isOpen={isLoading} text="読み込み中です、少々お待ちください。" />
      <div id="embed_line">
        <div>
          <h2>紹介プログラム</h2>
          {
            isAlreadyPerson ? (
              <div className="alert">
                すでに紹介プログラムに参加しています
              </div>
            ) : (
              <Form onSubmit={onSubmitCreationForm}>
                <div className="alert">
                  {isFormShown ? '下記フォームに入力し、登録してください。' : '下記ボタンを押して登録してください'}
                </div>
                <table className="form-table">
                  <tbody>
                    {
                      Object.entries(statedFields).map(([fieldName, fieldSetting]) => {
                        if(isShowField(fieldName, company)) return null
                        return  (
                          <Field
                            documentName="introduction"
                            key={fieldName}
                            name={fieldName}
                            {...fieldSetting}
                          />
                        )
                      })
                    }
                  </tbody>
                </table>
                <Button
                  block
                  className={classnames('btn', { '-inactive': isUnsubmittable })}
                  disabled={isEmpty(lineProfile) || isUnsubmittable || isSubmitting}>
                  登録する
                </Button>
              </Form>
            )
          }
        </div>
      </div>
    </>
  );
};

import React, { Component, Fragment } from 'react';
import { Button, } from 'reactstrap';

export default class ListForm extends Component {
  constructor() {
    super();
    this.state = {};
  }
  onClickAdd = () => {
    const { items, onChange, } = this.props;
    onChange([...items, {}]);
  }
  onClickDelete = (index) => {
    const { items, onChange } = this.props;
    onChange([...items.slice(0, index), ...items.slice(index + 1)], index);
  }
  render() {
    const { items, renderItem } = this.props;
    return (
      <Fragment>
        {
          items.map((item, i) => {
            return (
              <div key={i}>
                <table className="table">
                  <tbody>
                  {renderItem(item, i)}
                  <tr>
                    <td colspan="2">
                      <Button className="button -primary" onClick={this.onClickDelete.bind(this, i)}>
                        <span class="material-icons icn">delete</span>
                      </Button>
                    </td>
                  </tr>
                </tbody>
                </table>
              </div>
            )
          })
        }

        <div class="form__buttons">
          <Button className="button -primary" onClick={this.onClickAdd}>
            <span class="material-icons icn">add</span>
            <span class="txt">カスタム項目追加</span>
          </Button>
        </div>
      </Fragment>
    );
  }
};

module.exports = {
  webhookSendingLogStatuses: {
    successed: '成功',
    failed: '失敗',
  },
  entryStatuses: {
    waitingToFollow: '友達追加待ち',
    entryCompleted: '応募済み',
    rejected: '不採用済み',
    joined: '採用済み',
  },
  benefitTargetTypes: {
    applicant: '応募者',
    referrer: '紹介者',
  },
  userRoles: {
    admin: '管理者',
    staff: '一般',
  },
  benefitTargets: {
    all: 'すべて',
    applicant: '応募者のみ',
    referrer: '紹介者のみ',
    none: 'なし',
  },
  customItemTypes: {
    string: '1行テキスト',
    text: '複数行テキスト',
    select: 'セレクトボックス',
    radio: 'ラジオボタン',
  },
};

import React, { useState, } from 'react';
import { Button, Input } from 'reactstrap';
import { isUndefined, uniq, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { format as formatDate, } from 'date-fns';
import { useToggle } from 'react-use';
import Select from 'react-select';

import firebase from '../../firebase';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { confirm } from '../ConfirmModal';

const db = firebase.firestore();
const functions = firebase.app().functions('asia-northeast1');
const companiesRef = db.collection('companies');

export default function CompanyPeopleBenefits(props){
    const { companyId, peopleId } = props;

    const people = useDocumentSubscription(
        companiesRef
        .doc(companyId)
        .collection('people')
        .doc(peopleId),
        [companyId, peopleId]
        );

    const sentBenefits = useCollectionSubscription( companiesRef
        .doc(companyId)
        .collection('benefits')
        .where('sentTo','==',(people && people.lineProfile) ? people.lineProfile.userId : '-'),
        [companyId,people]
    );
    const [isSending, toggleSending] = useToggle(false);
    const [typeSend, setTypeSend] = useState(null);
    const [memo, setMemo] = useState('');

    // 未配送の特典から種類を抽出する
    const unsentBenefitsQuery = companiesRef
        .doc(companyId)
        .collection('benefits')
        .where('sentAt', '==', null)
        .where('sentTo', '==', null);

    const unsentBenefits = useCollectionSubscription(unsentBenefitsQuery);

    const benefitTypes = uniq(unsentBenefits.map(_ => ( _.type)).filter(_=> _ !== undefined))

    const benefitTypesOptions = benefitTypes.map(_ => ({ label: _, value: _ }));


    let hasValid = true;

    const onClickSendBenefit = async () => {

        if(!(await confirm('特典を送信確認', (
        <div className="benefits">
            <div className="form-line">
            {people.name}に {typeSend} を送る。
            </div>

            { 
            memo !== '' && <div className="form-line">
            <div style={{ whiteSpace: 'pre-line' }}>
            {memo}
            </div>
            </div>
            }
            <p>この内容で送信します。よろしいですか？</p>
        </div>
        )))) return;
        toggleSending(true);

        try {
            await db.runTransaction(async (t) => {
                console.log('Transaction start!');
                const snapshot = await companiesRef
                .doc(companyId)
                .collection('benefits')
                .where('sentAt', '==', null)
                .where('sentTo', '==', null)
                .where('type', '==', typeSend)
                .limit(1)
                .get();

                if (snapshot.empty) {
                    toast.error(people.name　+'への特典送信に失敗しました');
                    return;
                }
                const docRef = snapshot.docs[0].ref;
                await t.get(docRef);
                await t.update(docRef, {
                    sentTo: people.lineProfile.userId,
                    sentToType: 'referrer',
                    memo,
                });
                toast.success(people.name　+'への特典送信に成功しました');
            });
            console.log('Transaction success!');
        } catch (e) {
            console.log('Transaction failure:', e);
        }

        toggleSending(false);
    };

    if (isUndefined(people)) return <></>;
    if (isUndefined(sentBenefits)) return <></>;

    return (
    <section className="main__section">
        <h2 className="heading2">特典送付一覧</h2>

        <div className="table">
          <table>
            <thead>
            <tr>
                <th>特典種類</th>
                <th>送信日時</th>
                <th>メモ</th>
            </tr>
            </thead>
            <tbody>
                {
                  sentBenefits.map((benefit) => {
                    const { id, type, sentAt, memo } = benefit;
                    return (
                      <tr key={id}>
                        <td>
                          {type}
                        </td>
                        <td>
                          {sentAt && formatDate(sentAt.toDate(), 'yyyy/MM/dd HH:mm:ss')}
                        </td>
                        <td>
                          <div style={{whiteSpace: 'pre-line'}}>
                          {memo}
                          </div>
                        </td>
                      </tr>
                    )
                  })
                }
            </tbody>
          </table>
        </div>

        <h2 className="heading2">特典を送る</h2>
        <div className="benefit">
        {
          (benefitTypesOptions.length === 0) ? 
          <div className="form-line">特典が不足しているため、送ることができません。</div>
          : <>
          <div className="form-line">
            {people && people.name}に
            <div className="select-box">
              <Select
              options={benefitTypesOptions}
              value={benefitTypesOptions.find(_ => _.value === typeSend)}
              placeholder="特典種類"
              onChange={_ => setTypeSend(_.value)}
              />
            </div>
            を送る
          </div>
        <Input type="textarea" rows={5} value={memo} onChange={_ => setMemo(_.target.value)}　placeholder="特典メモ" />
        <div className="form__buttons">
          <Button onClick={onClickSendBenefit} className={classnames('button -primary', { '-inactive':!hasValid || isSending})} disabled={!hasValid || isSending}>
              <span className="material-icons icn">{(isSending)? 'autorenew':'send'}</span>
              <span className="txt">特典を送信</span>
          </Button>
        </div>
        </>
      }
      </div>
    </section>
  );
};

import React from 'react';
import { Input } from 'reactstrap';
import { range } from 'lodash';
import { getDaysInMonth, setYear, setMonth, setDate, getYear, getMonth, getDate } from 'date-fns';
import classnames from 'classnames';

export default function DateSelector (props) {
  const { value, onChange, className, readOnly, yearRange: [startYear, endYear] } = props;
  const year = getYear(value);
  const month = getMonth(value);
  const date = getDate(value);
  const onChangeYear = _ => onChange(setYear(value, _.target.value));
  const onChangeMonth = _ => onChange(setMonth(value, _.target.value));
  const onChangeDate = _ => onChange(setDate(value, _.target.value));
  const yearOptions = range(startYear, endYear + 1).map(_ => ({ label: `${_}年`, value: _ }));
  const monthOptions = range(0, 12).map(_ => ({ label: `${_ + 1}月`, value: _ }));
  const dateOptions = range(1, getDaysInMonth(value) + 1).map(_ => ({ label: `${_}日`, value: _ }));

  return (
    <div className="d-flex">
      <div style={{ width: 125 }}>
        <Input
          type="select"
          value={year}
          onChange={onChangeYear}
          className={classnames('form-select', className)}
          readOnly={readOnly}
          style={{ paddingRight: 30 }}
        >
          {
            yearOptions.map(({ label, value }) => {
              return <option value={value}>{label}</option>
            })
          }
        </Input>
      </div>
      <div style={{ width: 105 }} className="ml-1">
        <Input type="select"
          value={month}
          onChange={onChangeMonth}
          className={classnames('form-select', className)}
          readOnly={readOnly}
          style={{ paddingRight: 30 }}
        >
          {
            monthOptions.map(({ label, value }) => {
              return <option value={value}>{label}</option>
            })
          }
        </Input>
      </div>
      <div style={{ width: 105 }} className="ml-1">
        <Input type="select"
          value={date}
          onChange={onChangeDate}
          className={classnames('form-select', className)}
          readOnly={readOnly}
          style={{ paddingRight: 30 }}
        >
          {
            dateOptions.map(({ label, value }) => {
              return <option value={value}>{label}</option>
            })
          }
        </Input>
      </div>
    </div>
  );
};

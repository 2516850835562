const { isEmpty } = require('lodash');
const currentYear = (new Date()).getFullYear();

module.exports = {
  fields: {
    employeeNumber: {
      label: '従業員番号',
      type: 'string',
    },
    name: {
      label: '名前',
      type: 'string',
    },
    shopId: {
      label: '所属',
      type: 'categorized_select',
      options: [], 
      placeholder: '検索または選択する',
      category_placeholder: 'エリアで所属を絞る',
    },
    birthday: {
      label: '生年月日',
      type: 'date',
      selector: true,
      yearRange: [currentYear - 80, currentYear - 15],
      initialValue: new Date((new Date()).getFullYear() - 18, 0, 1),
    },
    joinedAt: {
      label: '入社日',
      type: 'date'
    }
  }
};
